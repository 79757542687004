
import React from 'react';
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CollapsibleDiv from './CollapsibleDiv';

const params = {
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
};

const styletext = { fontfamily: 'var(--bs-font-sans-serif)', 
                    lineHeight: '2.5',
                    textAlign: 'justify'
                }
class ShowDivMap extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
            metadata: [],
            models: [],
            msgError: '',
            isLoading: false,
            noLeftMargin:true,
            lakes: []
    
		}

		this.sizes = { sm: 12, //screen width < 576px
						xs: 12,  //screen width ≥ 576px
						md: 12, //screen width ≥ 768px
						lg: 6, //screen width ≥ 992px
						xl: 6 //screen width ≥ 1200px
        }
        this.placement = props.placement
        this.documentation = props.text
        this.URL = props.conexion
    
    }    
    openBox(){this.setState({show: true})}

	closeBox(){this.setState({show: false})}

render() {

    return (

    <>
               <Button variant="outline-success" onClick={()=>this.openBox()}>
            DIVMAP
                {/*<FontAwesomeIcon icon={faInfoCircle} size='lg'/>*/}
            </Button>

        <Offcanvas    //className="offcanvas-end-doc"
                    show={this.state.show}
	  				onHide={()=>this.closeBox()}
	  				scroll='true'
					backdrop='true'
                    placement='end'
                    bsPrefix='show-doc-box'
                    >
        <Offcanvas.Header closeButton >
	          <Offcanvas.Title>
               {this.state.msgError}

			  </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
             <Tabs
                    defaultActiveKey="metadata"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="metadata" title="Metadatos">
                      

                    </Tab>
                    <Tab eventKey="geoservices" title="Geoservicios">

                    </Tab>
                    <Tab eventKey="contact" title="Contacto" >
                    </Tab>
                    </Tabs>

			</Offcanvas.Body>
      </Offcanvas>

    </>

        )
    }
}
export default ShowDivMap;
