import React from 'react'

import { TileLayer, LayersControl,WMSTileLayer } from 'react-leaflet'


const BaseMaps = (props) => {
  
  const maps = props.data

  const mapas = maps.map((mapita,id) => (

      mapita.type === "base" ?

            <LayersControl.BaseLayer checked={mapita.default} name={mapita.name} key={mapita.id}>
                  <TileLayer
                        attribution={mapita.attribution}
                        url={mapita.url}
                        />
            </LayersControl.BaseLayer>
        :
          
            <LayersControl.Overlay name={mapita.name} key={id} checked={mapita.default}>

                    <WMSTileLayer layers={mapita.layers}
                                  url={mapita.url}
                                  transparent='true'
                                  format="image/png"
                                  tiled={mapita.tiled}

                   />


            </LayersControl.Overlay>
            
            
    ));
    return <LayersControl position={props.position} collapsed={true}>  {mapas}  </LayersControl>  
               
  };

export default BaseMaps;

