import React, { useState } from 'react';
import '../styles/CollapsibleDiv.css';

const CollapsibleDiv = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`collapsible-div ${isOpen ? 'open' : ''}`}>
      <div className="collapsible-header" onClick={toggleCollapse}>
        <span>{title}</span>
        <div className={`arrow ${isOpen ? 'open' : ''}`}></div>
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default CollapsibleDiv;
