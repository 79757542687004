import React from "react";

import {Button}  from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Container from 'react-bootstrap/Container'
import '../styles/modal.css';
import GeoservicesNew from './GeoservicesNew'
import GeoservicesList from './GeoservicesList'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../styles/button.css'


const mapStateToProps = (state) => {
    return {
        layers: state.layers
    };
  }


class AddLayers extends React.Component {

    constructor(props) {
      super(props);
      this.handleClose = this.handleClose.bind(this);
      this.handleShow = this.handleShow.bind(this);

      this.state = {
          disabled: false,
          show: false
      }
      //controla las propiedades del boton. deberian ser pasadas desde el compo padre.. json.. server.. etc
      this.propsbutton = {
          variant : "success",
          active : "True",
          text : "Agregar Geoservicio",
          position : props.position
      }
      this.sizes = { sm: 12, //screen width < 576px
        xs: 12,  //screen width ≥ 576px
        md: 12, //screen width ≥ 768px
        lg: 6, //screen width ≥ 992px
        xl: 6 //screen width ≥ 1200px
    }
      this.map = props.map

      this.datajson = []
    }

    //cierra el modal
    handleClose(){
        this.setState({show: false})
    }

    //abre el modal
    handleShow(){
        this.setState({show:true})
    }

    render() {

      return (
        <>
            <div className="d-grid gap-2" id={Math.random()}>

            <OverlayTrigger placement="auto"
																						delay={{ show: 250, hide: 400 }}
																						overlay={(props) => (
																									<Tooltip id="button-tooltip" {...props}>
                                                      Permite agregar capas provistas por geoservicios externos
																									</Tooltip>
																								)}
																						>
                    <Button variant="outline-success" size="sm"  onClick={() => this.handleShow()}>
                        {this.propsbutton.text}
                    </Button>
						</OverlayTrigger>


          </div>
            <Modal
                contentClassName="modal-container"
                show={this.state.show}
                backdrop="static"
                keyboard={false}
                centered
                scrollable
                size='lg' /*'sm' | 'lg' | 'xl'*/
                animation
                fullscreen='true' /*true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'*/
              >
                <Modal.Header>
                  <Modal.Title>Agregar Capas / Visualizar Metadatos</Modal.Title>
                </Modal.Header>

                  <Modal.Body className="show-grid " style={{position: 'relative', height: '2880px'}}>

                      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                        <Tab eventKey="home" title="Geoservicios disponibles " id="left-tabs">
                          <Tab.Container id={Math.random()}>
                          <Container className='container-text'>
                            <Row  className='container-item-text'>
                              <Col sm={'auto'} xs={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >
                                Listado de geoservicios y capas permanentes. Las capas seleccionadas se agregaran al panel de capas y podrá activarlas para visualizarlas y superponerlas a las demas.
                             </Col>
                            </Row>
                            </Container>
                              <Container fluid="true" id="contenido">

                              {/*Renderiza los divs de las capas y metadatos*/}
                              {' '}
                              {this.props.layers.map((service,key) => {
                                if(service.St && service.Id !== 'LOCAL'){
                                    return <><GeoservicesList map={this.map} idservice={service.Id} id={Math.random()+key+service.Id}> </GeoservicesList>{' '}</>

                                  }else{
                                    return null
                                  }
                                })
                              } {''}
                              </Container>
                           </Tab.Container>
                          </Tab>

                          <Tab eventKey="profile" title="Agregar geoservicio temporal">
                            <Tab.Container id={Math.random()} >

                                <Row  className='container-item-text'>
                                  <Col sm={'auto'} xs={'auto'} md={'auto'} lg={'auto'} xl={'auto'} >

                                    Los geoservicios aquí agregados se mantendrán temporalmente mientras la aplicación permanezca abierta.
                                    </Col>
                                </Row>

                                <Container fluid="true" >
                                  <GeoservicesNew map={this.map} id={Math.random()}></GeoservicesNew>
                                </Container>

                            </Tab.Container>

                          </Tab>

                  </Tabs>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="outline-success" onClick={() => this.handleClose()}>
                          Cerrar
                        </Button>
                      </Modal.Footer>

              </Modal>
      </>



      );
    }

  }
  export default connect(mapStateToProps)(AddLayers);

