
import React from "react";
import { connect } from 'react-redux'
import mysource from './MyWMSSource'


  const mapStateToProps = (state) => {
    return {
        layers: state.layers
    };
  }

class LoadGeoservices extends React.Component {
        _isMounted = false;

        constructor(props) {
            super(props);

            this.state = {
            }

            this.datajson = []
            this.capabilities = props.getcapabilitiesXML
            this.service = this.capabilities.map(element => {
                                    return element.response
                        });
            this.conexion = props.conexion
            this.URI_BAD = props.uri
        }

        getBoundingBox(obj){
            var boundingbox = []

            if ('EX_GeographicBoundingBox' in obj){
                boundingbox.push(obj.EX_GeographicBoundingBox.westBoundLongitude)
                boundingbox.push(obj.EX_GeographicBoundingBox.southBoundLatitude)
                boundingbox.push(obj.EX_GeographicBoundingBox.eastBoundLongitude)
                boundingbox.push(obj.EX_GeographicBoundingBox.northBoundLatitude)
            }else if ('LatLonBoundingBox' in obj) {
                boundingbox.push(obj.LatLonBoundingBox['@minx'])
                boundingbox.push(obj.LatLonBoundingBox['@miny'])
                boundingbox.push(obj.LatLonBoundingBox['@maxx'])
                boundingbox.push(obj.LatLonBoundingBox['@maxy'])

            }else{
                console.log('BBOX not found')
            }
            return boundingbox

        }
        getLayerType(obj){


            if ( obj && Array.isArray(obj.Keyword)){
                if (obj.Keyword.includes("features")) return "vector"
                if (obj.Keyword.includes("WCS")) return "raster"
            }
            return "undefined"

        }

        repair_url(url, src, dst){

            /* Repara la URL para el sercivio LOCAL, ya que el backend cuando genera el getcapabilities, lo hace con la url 
            del docker de geoserver (http://geoserver:8080), el cual no es consultable desde el frontend
            */
            let new_url = url
            let pattern_src = src
            var pattern_dst = dst
            if(url.includes(pattern_src) ){
                new_url = url.replace(pattern_src, pattern_dst)

            }
            return new_url
        }

        create_WMS_source(service,options){

            /* Crea una fuente WMS para interactuar con el mapa. Corrije la URL en el caso de que el servicio local venga con una IP interna, es decir: 
                http://geoserver:8080/geoserver/ows?SERVICE=WMS&request=getcapabilities
            */

            let href = service.WMS_Capabilities.Capability.Request.GetCapabilities.DCPType.HTTP.Get.OnlineResource["@xlink:href"]
            let url = this.repair_url(href, this.URI_BAD, this.conexion)
                       
            return mysource(url,options)
        }

        componentDidMount(){

            this._isMounted = true;

            /*
            datajson = [ { id: xxxx
                          name:xxxx
                          layers: [ {name: xxxx
                                    legend: xxxx
                                  } ]
                          metadate: xxxx
                         } ... ]

            */
            // Con la primer condicion, evito volver a inicializar el estado con lo services...
            // Con la segunda condicion, solo se carga cuando el componente esta montado, sino falla. (truco)
            if ((this.props.layers.length === 0) && (this._isMounted)){
                //log("Loading geoservices...")
                var leg = null
                //para cada geoservicio ..

                if (this.service.length !==0){
                    for (var j=0; j < this.service.length; j++) {
                        var layers = []
                        var datajson = []

                        var all_layers = []

                        if(!Array.isArray(this.service[j].WMS_Capabilities.Capability.Layer.Layer)) {
                            all_layers.push(this.service[j].WMS_Capabilities.Capability.Layer.Layer)
                        }else{

                            all_layers = this.service[j].WMS_Capabilities.Capability.Layer.Layer
                        }
                            for(var i=0; i < all_layers.length; i++){

                                    var url_original = ''

                                    if ('Style' in all_layers[i]) {

                                        if (Array.isArray(all_layers[i].Style)){

                                            url_original = all_layers[i].Style[0].LegendURL.OnlineResource["@xlink:href"]
                                            leg = this.repair_url(url_original, this.URI_BAD, this.conexion)
                                        }else{
                                            url_original = all_layers[i].Style.LegendURL.OnlineResource["@xlink:href"]
                                            leg = this.repair_url(url_original, this.URI_BAD, this.conexion)

                                        //-----------------------------------------------------------------
                                        }
                                    }else{
                                        leg = null
                                    }
                                    var bbox = []

                                    var type = this.getLayerType(all_layers[i].KeywordList)
                                    bbox = this.getBoundingBox(all_layers[i])

                                    url_original = this.service[j].WMS_Capabilities.Capability.Request.GetCapabilities.DCPType.HTTP.Get.OnlineResource["@xlink:href"]
                                    url_original = this.repair_url(url_original, this.URI_BAD, this.conexion)

                                    var getmap = url_original.split('ows')[0]+"ows?"

                                    var version = this.service[j].WMS_Capabilities['@version']
                                    var name = all_layers[i].Name
                                    var workspace = all_layers[i].Name.split(':')[0]
                                    var description = all_layers[i].Title || all_layers[i].Abstract

                                    /*var minilayer = `${getmap}&SERVICE=WMS
                                                        &VERSION=${version}
                                                        &REQUEST=GetMap
                                                        &LAYERS=${name}
                                                        &STYLES=
                                                        &SRS=EPSG:4326
                                                        &TRANSPARENT=TRUE
                                                        &BBOX=${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}
                                                        &WIDTH=400
                                                        &HEIGHT=400
                                                        &FORMAT=image/png`

                                    var clean_minilayer = minilayer.replace(/\s+/g, '');
                                    */
                                    layers.push( {  'name': name,
                                                    'workspace': workspace,
                                                    'legend': leg,
                                                    'getmap': getmap,
                                                    'state': false,
                                                    'boundingbox': bbox,
                                                    //'minilayer': clean_minilayer,
                                                    'type': type,
                                                    'description' : description,
                                                    'opacity': 1
                                                    }
                                                )
                            }

                            datajson.push(   {
                                                    'id': this.service[j].WMS_Capabilities.Id,
                                                    'name': this.service[j].WMS_Capabilities.Service.Title,
                                                    'url' : url_original.split('ows')[0],
                                                    'layers': layers,
                                                    'metadata' :  { 'Titulo' : this.service[j].WMS_Capabilities.Service.Title,
                                                                    'Resumen': this.service[j].WMS_Capabilities.Service.Abstract,
                                                                    'contact' : {
                                                                                    'ContactInformation': this.service[j].WMS_Capabilities.Service['ContactInformation']
                                                                                    }
                                                                    }
                                                })
                            var options = {'transparent': true,
                                            'tiled':true,
                                            'format': 'image/png',
                                            'feature_count': 10
                                        }
                            //update global state
                            this.props.dispatch({   type: 'ADD_NEW_LN' ,
                                                    St: true,
                                                    Id :this.service[j].WMS_Capabilities.Id,
                                                    name: this.service[j].WMS_Capabilities.Service.Title,
                                                    version: version,
                                                    source: this.create_WMS_source(this.service[j],options),
                                                    data: datajson
                                                });

                        }
                }else{
                    console.log("Services not found")
                }
            }else{
                console.log("state yet initialized!!")

            }
        }

        componentWillUnmount() {
            this._isMounted = false;
          }

        render() {

            return (
              <>


                </>
            );
        }
    }

    export default connect(mapStateToProps)(LoadGeoservices);

