
import React from "react";

import Card from 'react-bootstrap/Card'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import ListGroup from 'react-bootstrap/ListGroup'

import { connect } from 'react-redux'

import Form from 'react-bootstrap/Form'


const mapStateToProps = (state) => {
    return {
        layers: state.layers
    };
  }



class LayerName extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_id_service: '',
            exist: false
        }
        this.map = props.map
        this.id = props.id
        this.url = ''
        this.handleChange = this.handleChange.bind(this);
        this.handleLoad = this.handleLoad.bind(this);

    }

    handleChange(e) {

        //console.log("layer card: "+ e.target.checked);

        this.props.dispatch({
                            type: 'UPDATE_STATE_LAYER' ,
                            idservice: this.id,
                            name: e.target.defaultValue.split('||')[0],
                            description: e.target.defaultValue.split('||')[1],
                            state: e.target.checked
                        });
    }

    handleLoad(){
       this.setState({exist: true})
   }



    render() {
        return (

             <Card border="primary"
                              bg='Light'
                              text='dark'
                              className="mb-2"
                              style={{'height': '230px'}}
                            >
                            <Card.Header as="h6">Capas {this.props.layers.map(item => { if(item.Id === this.id){ return item.name }else{ return null}})} </Card.Header>
                            <Card.Body bsPrefix='card-body2'>
                                <ListGroup >
                                    { this.props.layers.map(item => {
                                        if (item.Id === this.id){
                                            if (item.data){
                                                if (Array.isArray(item.data[0].layers)){
                                                    return item.data[0].layers.map(l => {
                                                        return  <ListGroupItem bsPrefix='list-group-layername-item-item'>
                                                                    <ListGroup  horizontal >
                                                                       
                                                                        <ListGroupItem bsPrefix='layer-name'>
                                                                                    <Form.Check
                                                                                        type='checkbox'
                                                                                        required
                                                                                        name="capas"
                                                                                        label={l.name}
                                                                                        onChange={(e) => this.handleChange(e)}
                                                                                        /*id={l.name}*/
                                                                                        id={Math.random()}
                                                                                        defaultValue={l.name + '||' +l.description}
                                                                                        checked={l.state}
                                                                                    />
                                                                        </ListGroupItem>
                                                                    </ListGroup>
                                                                </ListGroupItem>
                                                    })
                                                }else{
                                                    return null
                                                }
                                            }else{
                                                return null
                                            }
                                        }else{
                                            return null
                                        }
                                    })
                                    }
                                </ListGroup>
                            </Card.Body>
                    </Card>

        )

    }
}
export default connect(mapStateToProps)(LayerName);
