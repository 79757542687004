import React from "react";
import Card from 'react-bootstrap/Card'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import ListGroup from 'react-bootstrap/ListGroup'

import { connect } from 'react-redux'


const mapStateToProps = (state) => {
    return {
        layers: state.layers
    };
  }


class LayerMetadata extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
        this.id = props.id
        this.metadata = []

        //get metadata from store
        this.getMetadata(this.id)


    }
    componentDidMount(){
    }

    getMetadata(id){

        var obj = this.props.layers.filter( elem => elem.Id === id )
        var myObj = obj[0].data[0].metadata
        this.parseNested(myObj)

    }

    parseNested(myObj){

       Object.entries(myObj).forEach(([key,value]) => {

                if (value !== undefined || value !== null) {

                    if (value && value instanceof Object) {
                        this.parseNested(value)
                    }else{
                        this.metadata.push({key: String(key),value: String(value)})
                    }
                }

            });
     }

    render() {
        return (
                   <Card border="primary"
                              bg='Light'
                              text='dark'
                              className="mb-2"
                              style={{'height': '230px'}}
                              id={this.id+'_mm'}
                            >
                            <Card.Header as="h6" style={{ position: 'sticky'}}>Metadatos</Card.Header>
                            <Card.Body bsPrefix='card-body-meta'>
                            <ListGroup>
                               {this.metadata.map((item,key) => {
                                            return (
                                                <ListGroupItem id={item.key+item.value+key}><b>{item.key}{': '}</b>{item.value}</ListGroupItem>
                                            )

                                        })}
                              </ListGroup>
                            </Card.Body>
                    </Card>
            )

    }
}

export default connect(mapStateToProps)(LayerMetadata);
