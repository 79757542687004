import React, {useState,useEffect, useCallback}  from "react";

import { MapContainer,
        ZoomControl,
        ScaleControl,
        Pane
    } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'
import Markers  from './Markers'
import BaseMaps  from './BaseMaps'
import '../styles/zoom.css'
import { makeStyles } from "@material-ui/core/styles";
import ErrorBoundary from './ErrorBoundary'
import CreateLeafletControl from './ControlOwn';
import Sidebar from './Sidebar'
import '../styles/sidebar.css'
import LoadGeoservices from "./LoadGeoservices";
import L from 'leaflet';
import {MinimapControl} from "./Minimap"
import '../styles/scale.css'
import PopUpMsg from "./PopUpMsg";
import '../styles/custom_positions.css'
import ResetViewMap from "./ResetViewMap";

import Search from './GeoSearch'
import { OpenStreetMapProvider } from 'leaflet-geosearch'

import ShowDoc from "./ShowDoc";

import ShowDivMap from "./ShowDivMap";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "90vh",
    },
  }));

const icon = L.icon({ iconUrl: "/leaflet_images/marker-icon.png" });

var service = []

const MapView = (props) => {
    
    const URI_BAD = props.config['map-data'].GEOSERVER_URL_BAD
    const URL_BASE = props.urlbase
    const configurations = props.config
    const geoservices = props.geoservices
    const LEVEL_ZOOM = props.config['map-data'].LEVEL_ZOOM
    const LEVEL_ZOOM_MINIMAP = props.config['map-data'].LEVEL_ZOOM_MINIMAP
    const classes = useStyles();
    const [position, updatePosition] = useState([props.config['map-data'].LONG_LAT.split(',')[0], props.config['map-data'].LONG_LAT.split(',')[1]])
    const switch_position = "bottomright";
    const zoom_position = "bottomright";
    const scale_position = "bottomright"
    const POSITION = {
      bottomleft: 'leaflet-bottom leaflet-left',
      bottomright: 'leaflet-bottom leaflet-right',
      topleft: 'leaflet-top leaflet-left',
      topright: 'leaflet-top leaflet-right',
      toprightcustom: 'leaflet-top-leaflet-right',
      middleright: 'leaflet-middle-right leaflet-middle-right:hover',
      otro:'MapControls_wrapper',
      toprightdispleft: 'leaflet-top-right-disp-left',
      toprightdown: 'leaflet-top-leaflet-right-down',
      toprightdisplefthome: 'leaflet-top-right-disp-left-home'
    }
  //Las tres constantes que siguen son para inicializar la ventana inicial


    const placement = { left: 'start',
                        right: 'end',
                        top: 'top',
                        bottom: 'bottom'
            }

    const [map, setMap] = useState(null);


    return (
    <>
        <MapContainer center={position}
                        zoom={LEVEL_ZOOM}
                        zoomControl={false}
                        className={classes.root}
                        whenCreated={setMap}
              >
           
            <div className={POSITION.toprightdispleft} >

                {/*map === null ? null : ( <ResetViewMap placement={placement.right} 
                            coords={[props.config['map-data'].LONG_LAT.split(',')[0], props.config['map-data'].LONG_LAT.split(',')[1]]} 
                            map={map}>
                            zoom={LEVEL_ZOOM}
                </ResetViewMap>)*/}
                <ShowDoc placement={placement.right} text={configurations.documentation} conexion={URL_BASE}></ShowDoc>
                {/*<ShowDivMap placement={placement.right} text={'DIVMAP'} conexion={URL_BASE}></ShowDivMap>*/}

            </div>        

            {/*<MinimapControl position={POSITION.toprightdown} zoom={LEVEL_ZOOM_MINIMAP} />*/}
            <ErrorBoundary>
                <LoadGeoservices conexion={URL_BASE}
                                 getcapabilitiesXML={geoservices['outputs-fetch']}
                                 uri={URI_BAD}
                                 >
                </LoadGeoservices>
            </ErrorBoundary>

            <ScaleControl  position={scale_position} />
            <Markers position={position} icon={icon} ></Markers>

            <div className={POSITION.middleright} id="panelbotones">
            </div>

            <ZoomControl position={zoom_position} />
            <BaseMaps  position={switch_position} data={configurations['base-maps']}/>

            <CreateLeafletControl bsPrefix='leaflet-middle-right' position="topright" id="controlbotones" >

                  <Pane />
            </CreateLeafletControl>

            <CreateLeafletControl position="topleft" >
              {map === null ? null : (<Sidebar  map={map} conexion={URL_BASE} link={configurations.documentation.cube}></Sidebar>) }
              </CreateLeafletControl>
          

          
            <Search provider={new OpenStreetMapProvider()} />
            <PopUpMsg msg={configurations['message-popup'].msg} 
                      title={configurations['message-popup'].title} 
                      open={configurations['message-popup'].open}>
                
            </PopUpMsg>
        </MapContainer>

      </>
    )

}
export default MapView;