import React from "react";
import FormOWS from './FormOWS';


class GeoservicesNew extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
        //controla la propiedad resposiva de las capas de geoservicios
        this.sizes = {  sm: 12, //screen width < 576px
                        xs: 12,  //screen width ≥ 576px
                        md: 12, //screen width ≥ 768px
                        lg: 6, //screen width ≥ 992px
                        xl: 6 //screen width ≥ 1200px
                }
        this.map = props.map


    }

    render()
    {
        return(

                <FormOWS map={this.map} ></FormOWS>
        )
    }
}

export default GeoservicesNew;