import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React from 'react';
import Button from 'react-bootstrap/Button'

class PopUpMsg extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
                open: this.props.open

        }

    }
    handleToClose(){
        this.setState({open: false});
      };


    render() {

            return (

                <Dialog open={this.state.open} onClose={() => this.handleToClose()}>
                    <DialogTitle>{this.props.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.msg}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                    <Button onClick={() => this.handleToClose()}
                            color="primary" autoFocus variant="outline-success">
                        Cerrar
                    </Button>
                    </DialogActions>
              </Dialog>

                )
        }
}
export default PopUpMsg;
