import React, { Fragment } from "react";
import Form from 'react-bootstrap/Form'
import {ButtonToolbar, Button}  from 'react-bootstrap'
import { Typeahead  } from 'react-bootstrap-typeahead';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Papa from 'papaparse';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/calendar.css'
import '../styles/filters.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar} from '@fortawesome/free-regular-svg-icons'

//redux
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
      layers: state.layers,
	  panel: state.panel
    };
  }


const params = {
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
};

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

        dict: {}, // {'provincia': [lista de lagos]}
        prov: null,
        lake: '',
        sensor: '',
        product: '',
        product_modis: '',
        startdate: new Date(),
        enddate: new Date(),
        isLoading: false,
        isLoading1: false,
        isLoadingProvs: false,
        isLoadingLakes: false,
        isLoadingSensors: false,
        isLoadingProds: false,
        isLoadingLayers: false,
        isLoadingRequests: false,
        isLoadingLay: false,
        expands_initial: false,
        expands_final: false,
        show_filters_date: false,
        disabledMod: false,
        locations: [], //respuesta del endpoint: provincias
        provs: [], //lista de nombres de prvincias
        lakes: [], //lista de nombres de lagos seleccionados por provincia
        sensors: [], //lista de sensores seleccionados
        products: [], //lista de modelos dado un un sensor (que no sea modis) y un lago
        products_modis: [], //lista de modelos (dado el sensor modis) y un lago
        id_request: null,
        id_model: null,
        frecuently_data: [],
        historical_data: [],
        request: [],
        products_name: [], //lista de solo nmbres de capas
        layers_name: [],        


        layer: [], //lista de nombres de capas unicamente
        layers: [], //lista de nombre y descripcion de las capas por producto
        date_value_list: [],
        available_dates: [],
        enable_button_addpanel: true,
        enable_button_clearfilter: true,

        disabled: false, //manage fields
        disabledButton: true, //button downdload csv
        switch: false,
        isGettingStats: false,
        isOpen: false
    };
    this.URL=props.conexion
    this.wms_position = "topright";
    this.size = 'sm' //| 'lg' | undefined;
    this.sites = []
    this.models = []
    this.products = []
    this.names_products = []
    this.layers = []
    this.message = 'Sin resultados'
    this.meta = []

    this.messagepopup = "Seleccionar Producto y revise que la Fecha Inicial sea anterior a la Fecha Final"

    this.sel_prov = React.createRef()
    this.sel_lake = React.createRef()
    this.sel_sensor = React.createRef()
    
    this.sel_prod = React.createRef()
    this.sel_layer = React.createRef()
    this.sel_startdate = React.createRef()
    this.sel_enddate = React.createRef()


    this.styles = {
        input: 'w-full border py-2 px-4 text-lg outline-none rounded-md',
        listbox: 'bg-neutral-900 w-full text-slate-50 rounded-md',
        highlightedItem: 'bg-neutral-800',
        query: 'text-oldsilver-800 placeholder:text-slate-600',
        typeahead: 'text-slate-500',
        clearButton:
          'absolute inset-y-0 text-lg right-0 w-10 inline-flex items-center justify-center bg-netural-700 hover:text-red-500',
        noItems: 'cursor-default text-center my-20',
        match: 'font-semibold',
        groupHeading: 'px-5 py-3 text-pink-500',
      }

    this.last = []

    this.msg_notavailable = "Productos no disponibles"

}

    async searchData(){

        this.setState({isLoadingProvs: true})

        try {
            var response = await fetch(`${this.URL}locations`, params)
                if(response.ok){
                    var data =  await response.json();
                    //console.log(data)
                    var elems = Object.entries(data)
                    var el;
                    var dict = {}
                    for (el in elems) {
                        dict[elems[el][0]] = elems[el][1].map(i =>{
                                                return i.name
                                                })
                    }
                    //console.log(dict)
                    
                    this.setState({dict: dict})
                    
                    this.setState({provs: Object.keys(dict)})
                                        
                }else{
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
            }catch(error){
                console.error(error);
            }

       this.setState({isLoadingProvs: false})
    }

    componentDidMount() {
        this.searchData()
        this.searchRequests()

    }

    setProvs(e){

        this.setState({isLoading: true})

        if (e.length !== 0){
            this.setState({
                    prov: e
            })
            //console.log(this.state.dict)
            this.setState({lakes: this.state.dict[e[0]]})
            
        }else{

            this.setState({
                prov: '',
                lake: '',
            })
            this.lakes = []
        }
        this.setState({isLoading: false})

    }

    async searchSensors(lake){

        this.setState({isLoadingSensors: true})

        try {
            var response = await fetch(`${this.URL}list_sensors?lake=${lake}`, params)
                if(response.ok){
                    var data =  await response.json();
                    //console.log(data)
                   
                    var sensors = data.map( el => {
                        return el.name
                    })
                    this.setState({sensors: sensors})
                  
                }else{
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
            }catch(error){
                console.error(error);
            }

       this.setState({isLoadingSensors: false})
    }

    setLakes(e){

        this.setState({isLoading: true})

        if (e.length !== 0){

            this.setState({ lake: e})

            this.searchSensors(e[0])

                
        }else{

            this.setState({
                lake: ''
            })
            this.lakes = []

        }
        this.setState({isLoading: false})

    }

    async searchProducts(sensor, lake){

        this.setState({isLoadingProds: true})

        try {

            var response = await fetch(`${this.URL}list_model?sensor=${sensor}&lake=${lake}`, params)

            if(response.ok){
                var data =  await response.json();
                //console.log(data)
               
                if( sensor.toLowerCase().includes('MODIS'.toLowerCase())) {
                    var products_modis = []
                    products_modis = data.map(el => {
                              return {'id': el._id ,
                                        'name': el.name,
                                        'description': el.description,
                                        'formula': el.formula
                                }
                    })
                    this.setState({ products_modis: products_modis})

                }else{
                    var products = []
                    products = data.map(el => {
                                return {'id': el._id ,
                                        'name': el.name,
                                        'description': el.description,
                                        'formula': el.formula
                                        }
                    })
                    this.setState({ products: products})
                }
                

            }else{
                throw new Error(`HTTP error, status = ${response.status}`);
            }
        }catch(error){
            console.error(error);
        }
        this.setState({isLoadingProds: false})
    }

    
    async searchRequests(){

        this.setState({isLoadingRequests: true})

        try {
            var response = await fetch(`${this.URL}list_request`, params)
            if(response.ok){
                var data =  await response.json();
                this.setState({ request: data})
                
            }else{
                throw new Error(`HTTP error, status = ${response.status}`);
            }
        }catch(error){
            console.error(error);
        }
        this.setState({isLoadingRequests: false})
    }


    setSensor(e) {
        //console.log(e)
        this.setState({isLoading: true})

        if (e.length !== 0){
            //se habia elegido un sensor y el seleccionado ahora no es modis...
            if ( this.state.sensor && !e[0].toLowerCase().includes('MODIS'.toLowerCase())) {
                //oculto los filtros
                this.setState({show_filters_date: false})
                this.setState({ disabled: false})
                this.sel_prod.current?.clear()
                this.sel_layer.current?.clear()
            }
            //se habia elegido un sensor, era modis y el seleccionado ahora tamien es modis (hay varios modis)...
            if ( this.state.sensor && this.state.sensor[0].toLowerCase().includes('MODIS'.toLowerCase()) && e[0].toLowerCase().includes('MODIS'.toLowerCase())) {
                //this.sel_prodmod.current?.clear()
                this.setState({ disabledButton: true})
                this.setState({ disabled: true})

            }
            //selecciono modis...
            if ( e[0].toLowerCase().includes('MODIS'.toLowerCase())) {
                //muestro los filtros
                this.setState({ show_filters_date: true})
                this.setState({ disabledButton: true})
                this.setState({ disabled: true})
            }

            this.setState({ sensor: e})
            this.searchProducts(e[0], this.state.lake)
            
        }else{

            this.setState({
                sensor: '',
                show_filters_date: false
            })
            this.sensors = []
        }
        this.setState({isLoading: false})
    }
  
    
    async searchMetadata(id_model, id_request){

        this.setState({isLoadingMetadata: true})

        try {

            var response = await fetch(`${this.URL}metadata/${id_model}/${id_request}`, params)

            if(response.ok){
                
                var data =  await response.json();
                //console.log(data)
                var historical_data = data.historical_data
                var frecuently_data = data.frecuently_data

                this.setState({ frecuently_data: frecuently_data })
                this.setState({ historical_data: historical_data })

                this.setState({id_model: id_model})
            
            }else{
                throw new Error(`HTTP error, status = ${response.status}`);
            }
        }catch(error){
            console.error(error);
        }
        this.setState({isLoadingMetadata: false})
        //console.log(this.state.frecuently_data)
    }

    async searchValuesModis(id_model,start_date, end_date){

        this.setState({isLoadingMetadata: true})

        try {

            var response = await fetch(`${this.URL}data_modis/${id_model}?start_date=${start_date}&end_date=${end_date}`, params)

            if(response.ok){
                var data =  await response.json();

                var date_value = data.map(el => {
                        return [el.date, el.value]
                    })
                
                date_value.unshift(["date", "value"])
                
                //console.log(date_value)
                this.setState({date_value_list: date_value })
                this.setState({id_model: id_model})

            }else{
                throw new Error(`HTTP error, status = ${response.status}`);
            }
        }catch(error){
            console.error(error);
        }
        this.setState({isLoadingMetadata: false})
        ////console.log(this.frecuently_data)
    }

    setValuesModis(e){
        
        if (e.length !== 0){
            this.setState({ product_modis: e })
         
       }else{

            this.setState({
                site: '',
                product_modis:''                
            })
        }

    }

    async setProd(e){

        this.setState({isLoadingLay: true})
        this.setState({ frecuently_data: []})
        this.setState({ historical_data: []})
        
        if (e.length !== 0){

            this.setState({ product: e })

            //si ya habia seleccionado producto y cambio por otro diferente...
            if ( this.state.product && e[0] !== this.state.product){
                //console.log("caso 1")
                
                this.setState({ layers_name: []})

                }
            
            var request = this.state.request.filter(el => el.sensor.name === this.state.sensor[0] && el.request.name === this.state.lake[0])
            
            //console.log("request id:", request[0])

            var model = this.state.products.filter(el => e[0] === el.name )
            //console.log("model id", model[0])
            
            if (model.length !== 0 && request.length !==0) {
                await this.searchMetadata(model[0].id, request[0].id)
                
                try {
                    // Wait for the asynchronous operation to complete
                    await this.waitForData();
                    
                    if (this.state.frecuently_data.length !== 0) {
                        var wk = this.state.frecuently_data.metadata.workspace
                        
                        var layers = this.state.frecuently_data.layers

                        layers.map( el =>{
                                        el.archive = wk +':'+el.archive.split('.')[0]
                        })

                        this.setState({layers_name: layers})

                    }
                }catch (error) {
                    console.error("Error:", error);
                }
            }
          
       }else{

            this.setState({
                site: '',
                product:''                
            })
        }
        this.setState({isLoadingLay: false})

    }


    waitForData() {
        return new Promise(resolve => {
            const checkData = () => {
                if (this.state.frecuently_data.length !== 0 || this.state.historical_data.length !== 0) {
                    //console.log("promesa resuelta...:", this.state.frecuently_data)
                    resolve();
                } else {
                    setTimeout(checkData, 500);
                }
            };
            checkData();
        });
    }
    
    setLayers(e){
        //console.log("layer agregada al panel:",e[e.length-1])
        if(e[e.length-1] !== undefined){
            this.setState({layer: [...this.state.layer, e[e.length-1]]})
            //objeto layer ({name, description}) tomado a partir de la capa elegida en el panel
            this.last = [...new Set(this.last.filter(element => {
                return element !== undefined;
                }))];
            //console.log(this.state.layers_name.filter(el => el.archive !== e[e.length-1].archive))
            this.setState({ layers_name: this.state.layers_name.filter(el => el.archive !== e[e.length-1].archive)})

        }
    }


    object_to_string(obj, exclude){
        
        var result = ''
        if (exclude){
            Object.entries(obj).forEach(([key, value]) => {
                if (!exclude.includes(key)) {
                    result = result + key +':'+value+'\n'
                }
            })
        }else{
            Object.entries(obj).forEach(([key, value]) => {
                result = result +key +': '+value+'\n'
            })
        }
        //console.log(result)
        return result
    }


    addToPanel(){

        /*
        Agregar la capa al panel desde los filtros, implica conectar al estado global y setear visibilidad a true
        */
            if(this.state.layer && this.state.layer.length !== 0){

                var layers = []
                //console.log("en this.state.layer tengo:", this.state.layer)

                var layers_selected = this.state.layer.filter(el=> el !== undefined  && el.archive.toLowerCase() )
                //console.log("me quede con:", layers_selected)
               
                
                layers_selected.forEach(ly => {
                    layers.push({'name': ly.archive.split('.')[0], 'description': this.object_to_string(ly, ['id', 'product','archive','date'])})
                })
                //console.log(layers)

                this.props.dispatch({
                            type: 'UPDATE_STATE_LAYER_LOCAL' ,
                            idservice: 'LOCAL',
                            state: true,
                            layers: layers
                    });
        }
            //fin codigo nuevo
    }

    date_utc_to_en(date){

        var getYear = date.toLocaleString("default", { year: "numeric" });
        var getMonth = date.toLocaleString("default", { month: "2-digit" });
        var getDay = date.toLocaleString("default", { day: "2-digit" });
        var dateFormat = getYear + "-" + getMonth + "-" + getDay;
        return dateFormat
    }

    ClearAll(){
        //reset all filters
        this.sites = []
        this.products = []
        this.names_products = []
        this.layers = []
        this.last = []
        this.setState({
            dict: {}, // {'provincia': [lista de lagos]}
            prov: null,
            lake: '',
            sensor: '',
            product: '',
            product_modis: '',
            startdate: new Date(),
            enddate: new Date(),
            isLoading: false,
            isLoading1: false,
            isLoadingProvs: false,
            isLoadingLakes: false,
            isLoadingSensors: false,
            isLoadingProds: false,
            isLoadingLayers: false,
            isLoadingRequests: false,
            isLoadingLay: false,
            expands_initial: false,
            expands_final: false,
            show_filters_date: false,
            disabledMod: false,
            locations: [], //respuesta del endpoint: provincias
            //provs: [], //lista de nombres de prvincias
            lakes: [], //lista de nombres de lagos seleccionados por provincia
            sensors: [], //lista de sensores seleccionados
            products: [], //lista de modelos dado un un sensor (que no sea modis) y un lago
            products_modis: [], //lista de modelos (dado el sensor modis) y un lago
            id_request: null,
            id_model: null,
            frecuently_data: [],
            historical_data: [],
            products_name: [], //lista de solo nmbres de capas
            layers_name: [],        
    
    
            layer: [], //lista de nombres de capas unicamente
            layers: [], //lista de nombre y descripcion de las capas por producto
            date_value_list: [],
            available_dates: [],
    
            enable_button_addpanel: true,
            enable_button_clearfilter: true,
    
            disabled: false, //manage fields
            disabledButton: true, //button statistics
            switch: false,
            isGettingStats: false,
            isOpen: false
        })

        //borra los elementos seleccionados/visibles en los inputs del form
        this.sel_prov.current?.clear()
        this.sel_lake.current?.clear()
        this.sel_sensor.current?.clear()
        this.sel_prod.current?.clear()
        if (this.sel_prodmod){
            this.sel_prodmod.current?.clear()
        }
        this.sel_startdate.current?.clear()
        this.sel_enddate.current?.clear()
        this.sel_layer.current?.clear()

        this.searchData()
    }

    setStartDate(e){ this.setState({startdate: e}) }

    setEndDate(e){ 
        this.setState({enddate: e})  
        this.setState({disabledButton: false})
    }

    async download_csv(){

        var st_d = this.date_utc_to_en(this.state.startdate)
        var end_d = this.date_utc_to_en(this.state.enddate)
        
        if (st_d && end_d && st_d < end_d && this.state.product_modis){

            var model = this.state.products_modis.filter(el => this.state.product_modis[0] === el.name )
           
            if (model.length !== 0) {
                try {

                    await this.searchValuesModis(model[0].id, st_d, end_d)
                    
                    this.setState( {isGettingStats : true})

                    await this.waitForDatatoCSV();

                    const csvData = Papa.unparse(this.state.date_value_list);
                    const blob = new Blob([csvData], { type: 'text/csv' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = model[0].name+'_'+st_d+'_'+end_d+'.csv';
                    link.click();

                    this.setState( {isGettingStats : false})

                } catch (error) {
                    console.error("Error:", error);
                }
                this.setState({ disabledButton: true})
    
            }
        }else{
             this.handleShow()
        }
    }
    waitForDatatoCSV() {
        return new Promise(resolve => {
            const checkStats = () => {
                if (this.state.date_value_list.length !== 0) {
                    resolve();
                } else {
                    setTimeout(checkStats, 100);
                }
            };
            checkStats();
        });
    }
      
    handleClose = () => {
        this.setState({
                isOpen: false,
        });
    }
    
    handleShow = () => {
        this.setState({ isOpen: true });
    }
    toggleSizeInitial(e){
        
        this.setState({ expands_initial: !this.state.expands_initial})
        e.preventDefault();
    }   

    toggleSizeFinal(e){
        
        this.setState({ expands_final: !this.state.expands_final})
        e.preventDefault();
    }   
    render() {
   
    return (

        <Fragment>
          <Form>
            <Form.Group className="mb-3">
                <Form.Label></Form.Label>
                 <Typeahead
                  filterBy={(option, props) => {
                            if (props.selected.length) {
                            // Display all the options if there's a selection.
                            return true;
                            }
                            // Otherwise filter on some criteria.
                            return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

                        }}

                    style={{ marginTop: '5px' }}
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={(e) => this.setProvs(e)}
                    options={this.state.provs}
                    placeholder="Seleccionar Provincia...."
                    selected={this.state.prov}
                    size={this.size}
                    emptyLabel={this.message}
                    ref={this.sel_prov}
                    isLoading={this.state.isLoading}

                >
               </Typeahead>
                <Typeahead
                        filterBy={(option, props) => {
                            if (props.selected.length) {
                            // Display all the options if there's a selection.
                            return true;
                            }
                            // Otherwise filter on some criteria.
                            return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

                        }}
                    style={{ marginTop: '5px' }}
                    id="basic-typeahead-single1"
                    labelKey="name"
                    onChange={(e) => this.setLakes(e)}
                    options={this.state.lakes}
                    placeholder="Seleccionar Lago..."
                    selected={this.state.lake}
                    size={this.size}
                    emptyLabel={this.message}
                    ref={this.sel_lake}
                    isLoading={this.state.isLoading}

                />
                <Typeahead
                    filterBy={(option, props) => {
                            if (props.selected.length) {
                            // Display all the options if there's a selection.
                            return true;
                            }
                            // Otherwise filter on some criteria.
                            return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

                        }}
                    style={{ marginTop: '5px' }}
                    id="basic-typeahead-single2"
                    labelKey="name"
                    onChange={(e) => this.setSensor(e)}
                    options={this.state.sensors}
                    placeholder="Seleccionar sensor..."
                    selected={this.state.sensor}
                    size={this.size}
                    emptyLabel={this.message}
                    ref={this.sel_sensor}
                    isLoading={this.state.isLoading}

                />
                
                { this.state.show_filters_date && 
                    <div className="filter-content">
                        <label class="title">Descargar datos MODIS en formato CSV</label>
                        <Typeahead
                            className="rbt-date"
                            disabled={this.state.disabledMod}
                            filterBy={(option, props) => {
                                    if (props.selected.length) {
                                    // Display all the options if there's a selection.
                                    return true;
                                    }
                                    // Otherwise filter on some criteria.
                                        return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

                                }}
                            style={{ marginTop: '10px' }}
                            id="basic-typeahead-single3"
                            labelKey="name"
                            onChange={(e) => this.setValuesModis(e)}
                            options={this.state.products_modis.map(el =>{ return el.name })}
                            placeholder="Seleccione un producto..."
                            selected={this.state.product_modis}
                            size={this.size}
                            emptyLabel={this.message}
                            ref={this.sel_prodmod}
                            isLoading={this.state.isLoading1}
                        />
                        <div className="filter-content1">
                            <label >Seleccione fecha incial:</label>
                            <input  
                                    className="Inputdate"
                                    name="myInput" 
                                    defaultValue="Fecha Inicial" 
                                    value={this.state.startdate.toLocaleDateString('en-GB')}
                            />
                            <Button 
                                    onClick={(e) => this.toggleSizeInitial(e)}
                                    size="sm"
                            >
                                    <FontAwesomeIcon icon={faCalendar} size="lg" />
                            </Button>
                            {this.state.expands_initial && (
                                <div className="floating-calendar-container" >
                                    <Calendar locale='es-419' onChange={(e) => this.setStartDate(e)} value={this.state.startdate} />
                                </div>
                            )}
                        </div>

                        <div className="filter-content1">
                            <label >Seleccione fecha final:</label>
                            <input 
                                    className="Inputdate"
                                    name="myInput" 
                                    defaultValue="Fecha Final" 
                                    value={this.state.enddate.toLocaleDateString('en-GB')} 

                            />
                        <Button 
                                onClick={(e) => this.toggleSizeFinal(e)}
                                size="sm"
                                >
                            <FontAwesomeIcon icon={faCalendar} size="lg" />
                        </Button>
                        {this.state.expands_final && (
                            <div className="floating-calendar-container" >
                                <Calendar locale='es-419' onChange={(e) => this.setEndDate(e)} value={this.state.enddate} />
                            </div>
                        )}
                                        
                    </div>

                    <ButtonToolbar style={{ marginTop: '5px', marginLeft: '160px' }}>

                        <Button  style={{ marginRight: '5px' }} variant="outline-success" size="sm" onClick={() => this.download_csv()} disabled={this.state.disabledButton}>Descargar CSV

                            {this.state.isGettingStats ?
                                    <><Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                :
                                null
                            }
                        </Button>
                </ButtonToolbar>

                </div>

                }
                

                <Typeahead
                    disabled={this.state.disabled}
                    filterBy={(option, props) => {
                            if (props.selected.length) {
                            // Display all the options if there's a selection.
                            return true;
                            }
                            // Otherwise filter on some criteria.
                                return option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

                        }}
                    style={{ marginTop: '5px' }}
                    id="basic-typeahead-single3"
                    labelKey="name"
                    onChange={(e) => this.setProd(e)}
                    options={this.state.products.map(el =>{ return el.name })}
                    placeholder="Productos disponibles para la selección..."
                    selected={this.state.product}
                    size={this.size}
                    emptyLabel={this.message}
                    ref={this.sel_prod}
                    isLoading={this.state.isLoading1}
                />
                <Typeahead
                    disabled={this.state.disabled}

                    filterBy={(option, props) => {
                            //console.log("quien es option y prop:", option, props)
                            if (props.selected.length) {
                            // Display all the options if there's a selection.
                            return true;
                            }
                            // Otherwise filter on some criteria.
                            return option.archive.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                        }}
                    style={{ marginTop: '5px' }}
                    id="basic-typeahead-multiple"
                    labelKey="archive"
                    multiple
                    onChange={(e) => this.setLayers(e)}
                    options={this.state.layers_name ? this.state.layers_name.filter(el => el.archive): []}
                    placeholder="Seleccione las capas disponibles..."
                    //selected={this.state.layer}
                    emptyLabel={this.message}
                    ref={this.sel_layer}
                    isLoading={this.state.isLoadingLay}

                />
                 <ButtonToolbar style={{ marginTop: '5px' }}>
                    <Button style={{ marginRight: '10px' }} variant="outline-success" size="sm" onClick={() => this.ClearAll()} disabled={this.enable_button_clearfilter}>Limpiar Filtros</Button>
                    <Button variant="outline-success" size="sm" onClick={() => this.addToPanel()} disabled={this.enable_button_addpanel}>Agregar al Panel</Button>
                    
                </ButtonToolbar>

            </Form.Group>

            </Form>
            
            <Modal 
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={true}
            show={this.state.isOpen} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.messagepopup}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>


        </Fragment>


    );
  }
}

export default connect(mapStateToProps)(Filters);

