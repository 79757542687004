//react
import React from 'react';
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import ListGroup from 'react-bootstrap/ListGroup'
import AddLayers from './AddLayers'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt} from '@fortawesome/free-regular-svg-icons'
import { faSortDown, faInfoCircle, faLayerGroup} from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { DragDropContext,Draggable,Droppable } from 'react-beautiful-dnd'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import RangeSlider from 'react-bootstrap-range-slider';
//import Image from "react-bootstrap/Image";
import Filters from './FiltersBox'
import Offcanvas from 'react-bootstrap/Offcanvas'
import TooltipLink from './ToolTipLink';
//leaflet
import L from 'leaflet'
//redux
import { connect } from 'react-redux'

//css
import '../styles/button.css'
import '../styles/tooltip.css'

const mapStateToProps = (state) => {
    return {
      layers: state.layers,
	  panel: state.panel
    };
  }


class Sidebar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			layers: [], /* Format: layers = [
												{layer_name:
													{   isChecked: bool,
														workspace: workspace,
														object: layer_on_map,
														service: id_service_of_layer_name,
														legend: 'url_legend',
														hidden_legend: false,
														url: 'url of geoservice',
														link_down: [{test: algo, url: url}],
														down: true or false,
														type: raster or undefined,
														description: text,
														opacity: int,
													}
												}
											...]
					*/
			error: '',
			show: true,
			isHidden: true
		}

		this.opacity_current = 50
		this.map = props.map
		this.wms_position = "topleft";
		this.handleChange=this.handleChange.bind(this)
		this.handleClose=this.handleClose.bind(this)
		this.handleShow=this.handleShow.bind(this)
		this.layerLocal = React.createRef()
		this.layerExternal =  React.createRef()
		this.filters = React.createRef()
		this.sizes = { sm: 12, //screen width < 576px
						xs: 12,  //screen width ≥ 576px
						md: 12, //screen width ≥ 768px
						lg: 6, //screen width ≥ 992px
						xl: 6 //screen width ≥ 1200px
        }
	
		this.URL = props.conexion
		this.N = 1 // cantidad de cuadrates a dividir la descarga de capas
		this.CUBE = props.link
	}

	toggleVisibility(k){

		//this.setState({isHidden: !this.state.isHidden})

		var layer = this.state.layers.filter(el => k in el)

		if (layer.length === 1){

			var ll = layer[0]
			ll[k].hidden_legend = !ll[k].hidden_legend
			this.setState( ll);
		}
	}
	hidden(k){

		var layer = this.state.layers.filter(el => k in el)
		if (layer.length === 1){
			var ll = layer[0]
			return ll[k].hidden_legend
		}
	}

	get_Layer(layer){

		this.state.layers.map(el => {
			return console.log(el)
		})
		let lay = this.state.layers.filter(el => layer in el)

		if(lay.length !== 0){
			return Object.values(lay[0])[0].object
		}
		return null

	}

	centerMapToLayer(name_layer, id_service){

		const gs = this.props.layers.filter(el => el.Id === id_service);
		const lay = gs[0].data[0].layers.filter(el => el.name === name_layer)
		let corner1 = L.latLng(lay[0].boundingbox[1],lay[0].boundingbox[0])
		let corner2 = L.latLng(lay[0].boundingbox[3],lay[0].boundingbox[2])
		let bounds = L.latLngBounds(corner1, corner2)
		this.map.flyToBounds(bounds)

	}

	getSubset(name_layer){

		const gs = this.props.layers.filter(el => el.Id === 'LOCAL');
		const lay = gs[0].data[0].layers.filter(el => el.name === name_layer)
		return {'Long': [lay[0].boundingbox[0],lay[0].boundingbox[2]], 'Lat': [lay[0].boundingbox[1],lay[0].boundingbox[3]]}
	}

	loadLocalState(serviceId, urlLayer, nameLayer, legend, source, description, workspace, type,opacity){

		/* Actualiza el estado local, cada vez que algo cambia en el panel capas*/

		var name = nameLayer
		var layers = [...this.state.layers]
		var filteredLayers = layers.filter(el => name in el)
		var link_down = []
		var url = ''
		if (filteredLayers.length === 0){

			if(serviceId === 'LOCAL'){ //only download local layers
				url = urlLayer.replace('ows','wcs')
				link_down = this.getSubsetsCoverageWCS(nameLayer, url, type)
				
			}else{
				link_down = [{text: 'La descarga para capas de servidores externos no esta disponible', url: ''}]
			}
			link_down[0]['service'] = serviceId
			url =  urlLayer
			var wms_layer = source.getLayer(name)
			var obj = {[name]: {
								'isChecked': false, 
								'object': wms_layer, 
								'service': serviceId, 
								'legend': legend,
								'hidden_legend': false, 
								'url': url, 
								'description': description, 
								'workspace': workspace, 
								'link_down':link_down,
								'down': false, 
								'type':type, 
								'opacity':opacity
								
							}
					}
			layers.unshift(obj)
			this.setState( {layers });
		}
	}

	getAreastoDownload(bbox){

		let lat1 = Number(bbox['Lat'][0])
		let lat2 = Number(bbox['Lat'][1])
		let lng1 = Number(bbox['Long'][0])
		let lng2 = Number(bbox['Long'][1])

		let divisiones_latitud = Math.sqrt(this.N)
		let divisiones_longitud = Math.sqrt(this.N)

		let tamano_subdivision_latitud = (lat2 - lat1) / divisiones_latitud
		let tamano_subdivision_longitud = (lng2 - lng1) / divisiones_longitud
		var i=0;
		var j=0;
		var areas = []
		for(i=0; i<= divisiones_latitud -1; i++){
			for(j=0; j<= divisiones_longitud -1; j++){
				let point1 = [lat1 + i * tamano_subdivision_latitud, lng1 + j * tamano_subdivision_longitud]
				let point2 = [lat1 + (i + 1) * tamano_subdivision_latitud, lng1 + (j + 1) * tamano_subdivision_longitud]
				areas.push('subset=Long('+point1[1]+','+point2[1]+')&subset=Lat('+point1[0]+','+point2[0]+')')
			}
		}
		return areas
	}

	getSubsetsCoverageWCS(name_layer, url, type){

		var bbox = this.getSubset(name_layer)
		var areas = this.getAreastoDownload(bbox)
		var link_down = []
		if(type === 'raster'){
			name_layer = name_layer.replace(':','__')
			var i =0;
			for(i=0; i<areas.length;i++){
				let url_down = `${url.replace("ows","wcs")}service=WCS&request=GetCoverage&version=2.0.1&coverageId=${name_layer}&format=geotiff&crs=EPSG:4326&${areas[i]}`
				//let url_down_1 = url_down.replace('geoserver', 'download-geotiff')
				link_down.push({text: 'Subset', url: url_down})
			}
			
		}else{

			let url_down = `${url.replace("ows","wfs")}service=WFS&version=1.1.0&request=GetFeature&typename=${name_layer}&srsName=EPSG:4326&outputFormat=shape-zip`
			link_down.push({text: 'Subset', url: url_down})
		}
		return link_down
	}
	

	removeFromPanel(k){
		/*
		Elimina capas del panel, del mapa y actualiza el estado del checkbox de card correspondiente
		*/
		let nameLayer = k

		//remove from map (if was loaded)
		let layerInMap = this.state.layers.filter(el => nameLayer in el)

		if (layerInMap.length === 1){

			//console.log("removing from map...")

			var layer = layerInMap[0]
			var service = layer[nameLayer]['service']

			this.props.dispatch({
				type: 'REMOVE_SUBLAYER_TO_SOURCE' ,
				id_service: service,
				layer_name: nameLayer

			});
		}

		//remove from panel
		let layers = [...this.state.layers]
		let filteredLayers = layers.filter(el => !(nameLayer in el))
		this.setState({ ...this.state,  layers: filteredLayers});

		//update global state: uncheck from cards
		let temp = this.state.layers.filter(el => nameLayer in el)
		let id = temp[0][nameLayer].service
		this.props.dispatch({ type: 'UPDATE_STATE_LAYER' , idservice: id, name: nameLayer, state: false});
	}

	addLegend(nameLayer,legend){

		//console.log("addLegend...Updating local state...")
		var layers = [...this.state.layers]
		var filteredLayers = layers.filter(el => nameLayer in el)

		if (filteredLayers.length === 1){
			//("addLegend..Updating local state...")
			let layer = filteredLayers[0]
			layer[nameLayer].legend = legend
			this.setState( layer);

		}
	}

	handleChange(e){

		/* Principalmente, agrega o quita capas del mapa al tildar/destildar desde el menu.
			Usa el objeto layers creando en loadLocalState() para agregar o quitar del mapa
		*/

		var name =  e.target.defaultValue.split('|')[0]
		//("Layers  in panel: ", this.state.layers)

		//extrae el "layers" de "layers" por nombre
		var getLayer = this.state.layers.filter(el => name in el)
		//console.log("Layers to Add or Remove: ", getLayer)

		if (getLayer.length === 1){

			var layer = getLayer[0]
			var service = layer[name]['service']
			
			if (e.target.checked){

				layer[name].isChecked = !layer[name].isChecked
				this.setState( layer);

				//modificar el estado global para agregar sublayers al source
				this.props.dispatch({
							type: 'ADD_SUBLAYER_TO_SOURCE' ,
							id_service: service,
							layer_name: name  //AHORA

						});

				const ind = this.props.layers.findIndex(elem => elem.Id === service); //finding index of the item
				this.props.layers[ind].source.addTo(this.map)

				var legend =  e.target.defaultValue.split('|')[1]

				//console.log("Adding legend...")
				this.addLegend(name,legend)
				this.centerMapToLayer(name, service)

			}else{

				//console.log("Removing from map...")

				this.props.dispatch({
					type: 'REMOVE_SUBLAYER_TO_SOURCE' ,
					id_service: service,
					layer_name: name

				});

				//console.log("Updating local state...")
				layer[name].isChecked = !layer[name].isChecked
				this.setState( layer);

				//update global state PANEL with unchecked layer
				this.props.dispatch({   type: 'REMOVE_LAYER_IN_PANEL' ,
						name: name
				});

			}
		}else{
			console.log("Layer not found!")
		}
	}

	componentDidUpdate(prevProps, prevState){

		/*REQUERIDO: esta condicion chequea si el estado global cambio, entonces actualiza.
			Esto sucede al agregar o quitar capas del Card de geoservicios*/

			if (prevProps.layers !== this.props.layers){

			this.props.layers.map(item => {
				if (item.data && item.source){
					//console.log("Updating state to service: ", item.Id)
					if (Array.isArray(item.data[0].layers)){
						return item.data[0].layers.map(l => {
							//Es true cuando la capa fue seleccionada en el card para ser agregada al panel
							
							if (l.state === true){
								return this.loadLocalState(item.Id, l.getmap, l.name, l.legend, item.source, l.description, l.workspace, l.type, l.opacity)
							}else{
								return null
							}
						})
					}else{
						return null
					}
				}else{
					return null
				}
			})
		}
	}

	toggle(e){

		if (e.current.style.display === 'block'){
			e.current.style.display = 'none'
		}else{
			e.current.style.display = 'block'
		}
	}

	handleClose(){this.setState({show: false})}

	handleShow(){this.setState({show: true})}

	reOrderItems(startIndex, endIndex){
		const layers = this.state.layers
		const [removed] = layers.splice(startIndex,1)
		layers.splice(endIndex, 0, removed)
		return layers
	}

	reOrderLayers(result){

		const {source, destination } = result
		//si arrastro fuera del panel.. es destination es null
		if (!destination) {
			return;
		}
		//si aggaro y suelto en el mismo lugar: mismo index y mismo i del area dropabble..
		if (source.index === destination.index &&
			source.droppableId === destination.droppableId) {
				return;
		}
		//Fuente para esta funcionalidad: https://qastack.mx/gis/137061/how-to-change-layer-order-in-leaflet-js
		if (Object.entries(this.state.layers[source.index])[0][1].isChecked){

			if (source.index < destination.index){ //arrastro la capa hacia abajo...

				//console.log("Dragging down...")
				Object.entries(this.state.layers[source.index])[0][1].object.bringToBack()
				Object.entries(this.state.layers[source.index])[0][1].object.remove();
				Object.entries(this.state.layers[source.index])[0][1].object.addTo(this.map);

				/*setZIndex(2 ó mayor ...):
					Se setea a 2 ó mayor ya que el mapa base es zindex = 1.
					Si se cargan otros mapas base, quizas esto no funcione y haya que incrmentarlo.
					Sin usar zindex, bringToBack manda la capa debajo del mapa base, en vez de sobre él.
				*/
				//Luego del campo de source, esta funcion al parece ya no hace falta
				//Object.entries(this.state.layers[source.index])[0][1].object.setZIndex(5)

			}else if(source.index > destination.index){ //arrastro la capa hacia arriba...

				//console.log("Dragging up...")
				Object.entries(this.state.layers[source.index])[0][1].object.bringToFront()
				Object.entries(this.state.layers[source.index])[0][1].object.remove();
				Object.entries(this.state.layers[source.index])[0][1].object.addTo(this.map);

			}else{
				console.log("Not reorder")
			}
		}
		//Ejecuto reordenamiento...
		this.setState({layers: this.reOrderItems(source.index, destination.index)})

	}

	changeOpacity(opacity, name_layer){

		/*Revisar control de opacidad, parece ser por source*/

		var layer = this.state.layers.filter(el => name_layer in el)

		if (layer.length === 1){

			var ll = layer[0]
			ll[name_layer].object.setOpacity(opacity)
			ll[name_layer].opacity = opacity
			this.setState( ll);

		}
	}

	render() {

	if (this.state.error) {
		return <h1>Caught an error: {this.state.error}</h1>
    }
	//definfing stye custom...
	const mystyle = {
					display: 'block',
					'overflowY': 'auto'
					};

  return (

<>

	<OverlayTrigger placement="right"
            delay={{ show: 250, hide: 100 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                            {"Consultar Productos disponibles"}
                </Tooltip>
                )}
                >
		<Button variant="success" onClick={()=>this.handleShow()}>
				<FontAwesomeIcon icon={faLayerGroup} size='lg' />
		</Button>
	 </OverlayTrigger>

      <Offcanvas show={this.state.show}
	  				onHide={()=>this.handleClose()}
	  				scroll='true'
					backdrop='true'
					className='sidebar-container'>
        <Offcanvas.Header closeButton >
	          <Offcanvas.Title>
			  
			  </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
		<Container fluid="true" className='my-1'>
				<Row>
					{<Col fluid="true">
						<AddLayers position={this.wms_position} map={this.map}></AddLayers>

					</Col>}
					<Col fluid="true">
						<OverlayTrigger placement="auto"
										delay={{ show: 250, hide: 400 }}
										overlay={(props) => (
										<Tooltip id="button-tooltip" {...props}>
                                                      Acceder el cubo de datos
										</Tooltip>
										)}
						>
							<Button variant='outline-success'
									size="sm"
									href={this.CUBE}
									target="_blank"
									>
									Cubo de datos
							</Button>
						</OverlayTrigger>
					</Col>
				</Row>
			</Container>
		<Container fluid="true" className='my-3'>
				<Row >
						<Col fluid="true">

								<Button bsPrefix="button-filter"
										onClick={() => this.toggle(this.filters)}
										size="sm"
										>
									Buscar Productos de Calidad del Agua
									<FontAwesomeIcon icon={faSortDown} size="sm" />
								</Button>

							</Col>
				</Row>
				<Row>
						<Col fluid="true" className="content-column">
							<div style={mystyle} ref={this.filters}>
							<Filters conexion={this.URL}></Filters>

							</div>
						</Col>
				</Row>

			</Container>
			<Container fluid="true" className='my-3'>
				<Row >
						<Col fluid="true" >
							<Button bsPrefix="button-filter"
									onClick={() => this.toggle(this.layerExternal)}
									size="lg" >
								Panel de Capas({this.state.layers.length})
								<FontAwesomeIcon icon={faSortDown} size="sm" />
							</Button>
						</Col>
				</Row>
				<Row>
					<Col fluid="true" >
					</Col>
				</Row>
			</Container>
		

			<div  ref={this.layerExternal} id="121" style={mystyle}>
			<DragDropContext onDragEnd={(result) => this.reOrderLayers(result)}>
					<Droppable droppableId="layers-panel">

					{(provided) => (

						<ListGroup variant="flush" className="characters" {...provided.droppableProps} ref={provided.innerRef}>

						{ (this.state.layers !==0) ?
								this.state.layers.map((el,id) => {
									for (let k in el){
										return <Draggable key={id} draggableId={id.toString()} index={id}>
      											{(provided) => (
													<ListGroupItem bsPrefix='list-group-item-general'
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	>
														<ListGroup horizontal bsPrefix='list-group-tools' >

															<OverlayTrigger placement="right"
																			delay={{ show: 250, hide: 400 }}
																			trigger='click' rootClose
																			overlay={(props) => (
																				<Tooltip id="button-tooltip" {...props} multiline={true} >

																						{ Object.values(el)[0].description ? Object.values(el)[0].description : "Metadato no disponible"}
																					
																				</Tooltip>
																				)}
																						>
																			<ListGroup.Item as='button' ref={this.meta} onClick ={() => {return 0}}
																												bsPrefix='list-group-item-item-tools'>
																					<FontAwesomeIcon icon={faInfoCircle} size='sm' />
																			</ListGroup.Item>
															</OverlayTrigger>
															{/*console.log("ver: ",Object.values(el)[0].link_down)*/}
														
															
															<TooltipLink linkText="Abrir Tooltip" tooltipContent={Object.values(el)[0].link_down} wk={Object.values(el)[0].workspace} ly={k} url={Object.values(el)[0].url} />

															
															 <OverlayTrigger placement="right"
																			delay={{ show: 250, hide: 400 }}
																			overlay={(props) => (
																				<Tooltip id="button-tooltip" {...props}>
																							{'Borrar del panel'}
																				</Tooltip>
																				)} >
															<ListGroup.Item as='button' onClick={() => this.removeFromPanel(k)}
																				bsPrefix='list-group-item-item-tools'>
																	<FontAwesomeIcon icon={faTrashAlt} size='sm' />
															</ListGroup.Item>
															</OverlayTrigger>

															<RangeSlider
																		value={el[k].opacity}
																		onChange={(e) => this.changeOpacity(e.target.value,k)}
																		size='sm'
																		variant='danger'
																		bsPrefix='range-slider'
																		min={0}
																		max={1}
																		step={0.1}
																		tooltip='off'
																		label='Opacidad'
																		/>
															<OverlayTrigger placement="right"
																			delay={{ show: 250, hide: 400 }}
																			overlay={(props) => (
																				<Tooltip id="button-tooltip" {...props}>
																							{'Mostrar/Ocultar leyenda'}
																				</Tooltip>
																				)} >
																<ListGroup.Item as='button' onClick={() => this.toggleVisibility(k)}
																					bsPrefix='list-group-item-item-tools'>
																	<FontAwesomeIcon icon={this.state.isHidden ? faEye : faEyeSlash} size='md' />
																</ListGroup.Item>
															</OverlayTrigger>

														</ListGroup>
														<ListGroup  horizontal>
															<ListGroup.Item bsPrefix='list-group-item-new'>
																<Form>
																	<Form.Group className="mb-3" id={k+"_ok"}>
																	<Form.Check
																		type='checkbox'
																		required
																		name="terms"
																		label={k}
																		onChange={(e) => this.handleChange(e)}
																		id={k}
																		defaultValue={k+'|'+el[k].legend}
																		checked={el[k].isChecked}
																			/>
																	</Form.Group>
																</Form>
															</ListGroup.Item>

														</ListGroup>
														<ListGroup.Item bsPrefix='list-group-item-legend'>
														
															{(el[k].isChecked)
																?
																<div id={k+"_legend"} className="legend-container" style={{ display: this.hidden(k) ? 'none' : 'block' }}>
																	<img src={el[k].legend} alt="Legend"  />
																</div>
																:
																null
															}
														

														</ListGroup.Item>
													</ListGroupItem>

												)}
										    </Draggable>
									}
								})
							:
							null
						}
						{provided.placeholder}
						</ListGroup>
					)}
					</Droppable>
			</DragDropContext>

			</div>


			
			
			</Offcanvas.Body>
      </Offcanvas>

    </>


  )
}
}
export default connect(mapStateToProps)(Sidebar);
