import { ADD_NEW_LN,DEL_NEW_LN,
        UPDATE_STATE_LAYER,
        UPDATE_STATE_LAYER_LOCAL,
        ADD_LAYER_IN_PANEL,
        REMOVE_LAYER_IN_PANEL,
        ADD_SUBLAYER_TO_SOURCE,
        REMOVE_SUBLAYER_TO_SOURCE } from '../actions';

const initialState = {
  layers: [],
  panel:[]
};

function reducer(state = initialState, action) {
    switch(action.type) {

    case ADD_NEW_LN:
        //console.log(action.data)
        return {
            ...state,
            layers: [...state.layers, {St: action.St, Id: action.Id, name: action.name, version: action.version, source: action.source ,data: action.data}]
            //layers: [...state.layers, {St: action.St, Id: action.Id, name: action.name, version: action.version, data: action.data}]
            };
    case DEL_NEW_LN:
        return {
            ...state,
            //layers: state.layers.filter(item => item.name !== action.name)
            //layers: [...state.layers,
        };

    case UPDATE_STATE_LAYER:

        const index = state.layers.findIndex(elem => elem.Id === action.idservice); //finding index of the item by id service
        const newArray = [...state.layers]; //making a new array
        const index2 = newArray[index].data[0].layers.findIndex(elem => elem.name === action.name)
        newArray[index].data[0].layers[index2].state = action.state
        newArray[index].data[0].layers[index2].description = action.description
        //console.log(newArray)
        return {
            ...state, //copying the orignal state
            layers: newArray, //reassingning layers to new array
           }

    case UPDATE_STATE_LAYER_LOCAL:

        //console.log(state.layers)
        const index_geoservice = state.layers.findIndex(elem => elem.Id === action.idservice); //finding index of the service
        var array_copy = [...state.layers]; //making a new array

        //console.log(array_copy)

        action.layers.forEach(el => {
            //console.log(el.name, el.description)
            let index_layer = array_copy[index_geoservice].data[0].layers.findIndex(elem => elem.name === el.name)
            if (index_layer !== -1){
                array_copy[index_geoservice].data[0].layers[index_layer].state = action.state
                array_copy[index_geoservice].data[0].layers[index_layer].description = el.description
            }else{
                console.log("layer not defined")
            }
        });

        //console.log(array_copy)
        return {
            ...state, //copying the orignal state
            layers: array_copy, //reassingning layers to new array
            }

    case ADD_LAYER_IN_PANEL:
        //console.log(state.panel)
        return {
                ...state,
                panel: [...state.panel, {name: action.name, url: action.url}]
         };

    case REMOVE_LAYER_IN_PANEL:
        const newState = state.panel.filter( item => item.name !== action.name );
        return {
            ...state, //copying the orignal state
            panel: newState, //reassingning layers to new array
           }

    case REMOVE_SUBLAYER_TO_SOURCE:
        const ind1 = state.layers.findIndex(elem => elem.Id === action.id_service); //finding index of the item
        const newarr1 = [...state.layers]; //making a new array

        newarr1[ind1].source.removeSubLayer(action.layer_name);
        //console.log("removing layer (array nuevo)...",action.layer_name, newarr1)
        return {
            ...state, //copying the orignal state
            layers: newarr1, //reassingning layers to new array
            }

    case ADD_SUBLAYER_TO_SOURCE:

        const ind = state.layers.findIndex(elem => elem.Id === action.id_service); //finding index of the item
        const newarr = [...state.layers]; //making a new array
        newarr[ind].source.addSubLayer(action.layer_name);

        //console.log(newarr)
        return {
            ...state, //copying the orignal state
            layers: newarr, //reassingning layers to new array
           }

    default:
        return state;
    }
}
export default reducer;
