import React from 'react';
import Button from 'react-bootstrap/Button'
import { faHome} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class ResetViewMap extends React.Component {
    constructor(props) {
      super(props);
        this.map = props.map
        this.coords = props.coords
        this.zoom = props.zoom
    }

    toggle() {
        this.map.flyTo(this.coords, this.zoom);
    }

render() {
    return (
        
           <>
           <OverlayTrigger placement="left"
            delay={{ show: 250, hide: 100 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                            {"Centrar Mapa"}
                </Tooltip>
                )}
                >
            <Button  onClick={() => this.toggle()}
                     variant="outline-success">
               <FontAwesomeIcon icon={faHome} size='lg' />
            </Button>
            </OverlayTrigger>

            </>
    )
}
}
export default ResetViewMap;