import './App.css';

import MapView from './components/MapView'
import React, {useState,useEffect}  from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@mui/material/Toolbar';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from '@mui/material/Box';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from "react-bootstrap/Image";
import Spinner from 'react-bootstrap/Spinner';

import './styles/app.css'
import './styles/sidebar.css'
import './styles/layername.css'
import './styles/geoserviceslist.css'
import './styles/imgpreview.css'
import './styles/layermetadata.css'
import './styles/button.css'
import './styles/custom_positions.css'
import "react-widgets/styles.css";
import './styles/popup.css'
import './sass/offcanvas.scss'
import './styles/filters.css'
import './styles/show_doc.css'
import '../node_modules/leaflet-geosearch/dist/geosearch.css';
import './styles/geosearch.css'
import './styles/table_meta.css'

const URL_BASE = process.env.REACT_APP_BASE_URL;

const URL_CONFIG = URL_BASE+'/configurations';
const URL_GEOSERVICES = URL_BASE+'/geoservices';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },

}));


const params = {
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
};


function Header() {
  //const estilo = style_1();

  // Import result is the URL of your image
  return <>
  <Container fluid={true}>
              <Row >
                <Col className="col-sm-auto" >
                <Image src={URL_BASE+'images/logos.png'}  
                        rounded 
                        fluid  
                        alt="INA" 
                        style={{ maxWidth: '560px', maxHeight: '70px' }} // También puedes aplicar estilos en línea

                        />
                </Col>
            
              </Row>
            </Container>

        </>;

}


function App() {
  const classes = useStyles();

  const [configurations, setConfig] = useState('')
  
  const [geoservices, setGeoservices] = useState('')

  const [errorMessage, setErrorMessage] = useState('');
  
  const [status, setStatus] = useState(false);

  const [isLoading, setSppiner] = useState(true);

  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
  
    setSppiner(true)

    var ignore = false;
  
    const fetchData = async () => {
      try{

        const response_cf = await fetch(URL_CONFIG,params);
        //console.log("Searching in API the data of configurations....", response)
        if(!response_cf.ok){
            throw new Error(`HTTP error, status = ${response_cf.status}`);
        }
        var data =  await response_cf.json();
        if (!ignore){
          setConfig(data);
          //setStatus(true)
          //setSppiner(false)
        }
        const response_gs = await fetch(URL_GEOSERVICES,params);
        //console.log("Searching in API the data of configurations....", response)
        if(!response_gs.ok){
            throw new Error(`HTTP error, status = ${response_gs.status}`);
        }
        data =  await response_gs.json();
        if (!ignore){
          setGeoservices(data);
          setStatus(true)
          setSppiner(false)
        }

      }catch(error){
          console.log("Fallo en fetch...")
          console.error(error);
      }
        return () => { ignore = true }
      }
    fetchData()
  }, [])
  
  //console.log(configurations)
 
  return (
  
    (errorMessage ? <p className="error"> {errorMessage} </p> :
        
    <div className={classes.root}>
       
       {(isLoading ? 
      <><Spinner
          bsPrefix='custom-spinner'
          as="span"
          animation="border"
          size="xl"
          role="status"
          aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span>
        </>

          :
        <>
        <AppBar position="static">
          <Toolbar variant="dense" >
          <Box sx={{ display: { xs: 'none', md: 'flex' }, padding: "10px"  }}>
            {Header()}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Typography variant="h5" color="inherit" align='center'>
              
                {( configurations ? configurations['map-data'].APP_TITLE : "SIN TITULO" )}
             
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      
      <Container maxWidth="xl">
        <div>
          {configurations && geoservices && status && <MapView config={configurations}
                                                               geoservices={geoservices}   
                                                                urlbase={URL_BASE}                                   
                                        ></MapView>}

        </div>
      </Container>
      </>
        )}
    </div>

      )
    );
}

export default App;
