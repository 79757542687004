import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LayerName from './LayerName'
import LayerMetadata from './LayerMetadata'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button';


class GeoservicesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            card: true
        }
        this.map = props.map
        this.id = props.idservice
        this.iddiv = this.id
        //controla la propiedad resposiva de las capas de geoservicios
        this.sizes = { sm: 12, //screen width < 576px
            xs: 12,  //screen width ≥ 576px
            md: 12, //screen width ≥ 768px
            lg: 6, //screen width ≥ 992px
            xl: 6 //screen width ≥ 1200px
        }
    }
        //this.layer = props.data
    setOpen(){
        this.setState({card: !this.state.card})

    }



    render() {

        return (

                    <div className='container-block-lymd' id={this.iddiv}>
                    <Button
                            className="btn btn-secondary w-100"
                            variant="primary" size="sm"
                            onClick={() => this.setOpen()}
                    >Geoservicio {this.id}
                    </Button>
                    <Collapse in={this.state.card}>

                                <Row fluid="true">
                                    <Col sm={this.sizes.sm} xs={this.sizes.xs} md={this.sizes.md} lg={this.sizes.lg} xl={this.sizes.xl}>
                                        <div className="layer-category" id={this.id+"_layer"} >
                                                <LayerName map={this.map} id={this.id} ></LayerName>
                                        </div>
                                    </Col>
                                    <Col sm={this.sizes.sm} xs={this.sizes.xs} md={this.sizes.md} lg={this.sizes.lg} xl={this.sizes.xl}>
                                        <div  className='layer-metadatos' id={this.id+"_meta"} >
                                                <LayerMetadata id={this.id}></LayerMetadata>
                                        </div>
                                    </Col>
                                </Row>
                    </Collapse>
                </div>


        )

    }
}

export default GeoservicesList;