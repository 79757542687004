import React, { useState } from 'react';
import '../styles/tooltip.css'; // Archivo CSS para estilos personalizados del tooltip
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from 'react-bootstrap/ListGroup'
import { faDownload} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const TooltipLink = ({ linkText, tooltipContent, wk, ly, url }) => {

  const [showTooltip, setShowTooltip] = useState(false);

  const [descargando, setDescargando] = useState({});
  
  const [puedeDescargar, setPuedeDescargar] = useState({});
  
  var url_sld = '';

  if (tooltipContent[0].service === 'LOCAL') {
            
            url_sld = url.replace('ows?','')+"/rest/workspaces/"+wk+"/styles/"+ly.split(':')[0]+'_'+ly.split(':')[1].split('_')[0]+".sld"
            console.log(url_sld)
  }
  
  console.log(url_sld)
  console.log(descargando)
  
  useState(() => {
    const initialState = tooltipContent.map((item, index) => {
      return {
        [index]: true,
      };
    }, {});
    setPuedeDescargar(initialState);
  }, [tooltipContent]);



  const handleDescargarArchivo = (id) => {
    setDescargando((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  
    const tiempoEspera = 3000; // 5 segundos (ajústalo según tus necesidades)

    // Obtener el tiempo de inicio de descarga
    const tiempoInicio = new Date().getTime();


    setTimeout(() => {
      setDescargando((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }, tiempoEspera - (new Date().getTime() - tiempoInicio)); // Ejemplo: Restablecer el estado después de X segundos

    //console.log(descargando)
  }
  const handleCargaCompleta = (id) => {
    console.log('handleCargaCompleta(id)',id )
    setPuedeDescargar((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const getBotonClase = (id) => {
    return descargando[id] ? 'boton-descargando' : '';
  };

    const handleLinkClick = () => {
      setShowTooltip(!showTooltip);
    };
  
    return (

<div className="tooltip-container" id='tooltip-nuevo'>
        <OverlayTrigger placement="right"
																			delay={{ show: 250, hide: 400 }}
																			overlay={(props) => (
																				<Tooltip id="button-tooltip" {...props}>
																							{'Descargar capa y estilo'}
																				</Tooltip>
																				)} >
                        <a href="#" >
                        <ListGroup.Item as='button' bsPrefix='list-group-item-item-tools' onClick={handleLinkClick}>
                          <FontAwesomeIcon icon={faDownload} size='sm' />
                        </ListGroup.Item>
                        </a>
        </OverlayTrigger>

        {console.log("link de descarga:",  tooltipContent)}

        {showTooltip && (
          <div className="box">
            <div className="box-arrow-border">
                <div className="box-arrow-background"></div>
            </div>
            <div className="box-content">
                                       
                  { tooltipContent[0].service === 'LOCAL' 
                      ?
                         <>
                         Descargar
                         <ButtonToolbar>

                          <ButtonGroup className="me-2" aria-label="First group" style={{ marginRight: '10px' }}>
                              <Button href={url_sld} 
                                      size="sm" 
                                      style={{ width: "40px", height: "30px"}} 
                                      variant="outline-primary"
                                    >
                                  <i className="fas fa-download"/>
                                  {'SLD'}
                              </Button>
                           {tooltipContent.map((item, index) => (

                                              <Button href={item.url} 
                                                      size="sm" 
                                                      style={{ width: "50px", height: "30px"}} 
                                                      variant="outline-primary"
                                                      onClick={() => {handleDescargarArchivo(index)}} 
                                                      disabled={descargando[index] || !puedeDescargar[index]}
                                                      className={`boton-descarga ${getBotonClase(index)}`}
                                                      onLoad={() => handleCargaCompleta(index)} 
                                                    >
                                                  <i className="fas fa-download"/>
                                                  {/*item.text+index*/}
                                                  {'Capa'}
                                              </Button>
                                ))}
                                </ButtonGroup>
                              </ButtonToolbar>
                            </>
                      :
                      <p style={{color: 'red'}}>La descarga para capas externas no esta disponible.</p>
                  }
                          
                
            </div>
          </div>
  
            )}
      </div>
    );
  };
  
  export default TooltipLink;