import React from 'react'

import { Marker,Popup } from 'react-leaflet'

/*
Crea el marker position:
    zIndexOffset={1000}: para manteberlo al tope de las capas siempre
    draggable={true}: permite arrastrarlo para llevarlo a otra ubicacion
    autoPan={true}: si al arrastrarlo, se sale de la visual del mapa, desplaza el mapa automaticamente
*/


const Markers = (props) => {
    return (
        <Marker position={props.position} icon={props.icon} zIndexOffset={1000} draggable={true} autoPan={true}>
            <Popup>
                     <br />
            </Popup>
    </Marker>
    )

};
export default Markers;