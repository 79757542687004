import Form from 'react-bootstrap/Form'
import React from "react";
import WMSCapabilities from 'wms-capabilities';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux'
import InputGroup from 'react-bootstrap/InputGroup'
import L from 'leaflet'
import mysource from './MyWMSSource'

import '../styles/form.css';

const mapStateToProps = (state) => {
    return {
        layers: state.layers
    };
  }


class FormOWS extends React.Component {

    constructor(props) {
      super(props);
      this.myRefSelect = React.createRef();
      this.myRefPanel = React.createRef();

      this.state = {
          disabled: false,
          showhideform: true,
          urlwms: "",
          //if was chargue layers
          fill: false,
          //if layer panel exist
          panel: true,
          select: false,
          message: '',
          geoserviceName: 'Sin Nombre'

      }
      this.sizes = { sm: 12, //screen width < 576px
                    xs: 12,  //screen width ≥ 576px
                    md: 12, //screen width ≥ 768px
                    lg: 6, //screen width ≥ 992px
                    xl: 6 //screen width ≥ 1200px
      }
      this.handleChangeName = this.handleChangeName.bind(this);
      this.handleChangeURL = this.handleChangeURL.bind(this);
      //request wms
      this.regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

      this.items = [];

      this.map = props.map

    }

    create_WMS_source(capability, options){

        var url = capability.Request.GetCapabilities.DCPType[0].HTTP.Get.OnlineResource.split('SERVICE')[0]
        //return WMS.source(url,options)
        return mysource(url,options)

    }

    getBoundingBox(obj){
        var boundingbox = []

        boundingbox.push(obj.EX_GeographicBoundingBox.westBoundLongitude || obj.EX_GeographicBoundingBox[0])
        boundingbox.push(obj.EX_GeographicBoundingBox.southBoundLatitude || obj.EX_GeographicBoundingBox[1])
        boundingbox.push(obj.EX_GeographicBoundingBox.eastBoundLongitude || obj.EX_GeographicBoundingBox[2])
        boundingbox.push(obj.EX_GeographicBoundingBox.northBoundLatitude || obj.EX_GeographicBoundingBox[3])

        return boundingbox

    }

    getLayerType(obj){


        if ( obj && Array.isArray(obj.Keyword)){
            if (obj.Keyword.includes("features")) return "vector"
            if (obj.Keyword.includes("WCS")) return "raster"
        }
        return "undefined"

    }


    handleChangeName(e){

        if(e.target.value){
            this.setState({geoserviceName: e.target.value})
        }
    }


    async _fetch(request) {
        const fetchResult = await fetch(request); //Making the req

        if (fetchResult.ok) {
          return fetchResult; // return success object
        }

        const result = await fetchResult.json(); // parsing the response

        const responseError = {
          type: 'Error',
          message: result.message || 'Something went wrong',
          data: result.data || '',
          code: result.code || '',
        };

        const error = new Error();
        error.info = responseError;

        return (error);
      }

     async handleChangeURL(e){
        //handleChange(e){

        this.setState({fill: false})

        const pattern = this.regex;
        var url = e.target.value

        //var flag = true
        const url_normal = url.toLowerCase()

        if (!url_normal.includes('getcapabilities')){
            url = url_normal + "?request=getcapabilities"

        }
        console.log(url)
        if (pattern.test(url) &&  !url.includes('wfs'.toLocaleLowerCase())){
            
            this.setState({urlwms:url})

            try {

                var response = await fetch(url)
                ////console.log(response.ok)
                var data =  await response.text();
                this.setState({select: true})


            }catch (err) {
                ////console.log('fetch failed: ', err);
                ////console.log(data)
                this.setState({message: "El Geoservicio no esta disponible. Consulte al administrador"})
                e.preventDefault();
                return 1

            }
            let json = new WMSCapabilities(data).toJSON();

            let layers = json.Capability.Layer.Layer
            var layers_global = []
            var datajson_global = []
            var leg = null
            for ( var i=0; i < layers.length; i++ ) {

                let l_name = json.Capability.Layer.Layer[i].Name
                ////console.log("nombres de capas de services:", l_name)
                let l_title = json.Capability.Layer.Layer[i].Title
                let abs = json.Capability.Layer.Layer[i].Abstract
                console.log(l_title)
                console.log(abs)
                let new_obj = {
                                value: l_name,
                                name: l_title,
                                minx: json.Capability.Layer.Layer[i].EX_GeographicBoundingBox[0],
                                miny: json.Capability.Layer.Layer[i].EX_GeographicBoundingBox[1],
                                maxx: json.Capability.Layer.Layer[i].EX_GeographicBoundingBox[2],
                                maxy: json.Capability.Layer.Layer[i].EX_GeographicBoundingBox[3]
                            }
                this.items.push(new_obj);

                if ('Style' in json.Capability.Layer.Layer[i]) {
                    if (json.Capability.Layer.Layer[i].Style[0].LegendURL[0].OnlineResource){
                        leg = json.Capability.Layer.Layer[i].Style[0].LegendURL[0].OnlineResource
                    }else{
                        leg = json.Capability.Layer.Layer[i].Style.LegendURL.OnlineResource["@xlink:href"]
                    }
                }else{
                    leg = null
                }
                var getmap = ''
                //////console.log(json.Capability.Request.GetCapabilities.DCPType[0].HTTP.Get.OnlineResource) //Request.GetCapabilities.DCPType.HTTP
                if (json.Capability.Request.GetCapabilities.DCPType[0].HTTP.Get.OnlineResource){
                     getmap = json.Capability.Request.GetCapabilities.DCPType[0].HTTP.Get.OnlineResource.split('ows')[0]
                }else{
                     getmap = json.Capability.Request.GetCapabilities.DCPType.HTTP.Get.OnlineResource["@xlink:href"].split('ows')[0]

                }
                ////console.log(getmap)
                var version = json.Capability['@version'] || json.version

                var name = json.Capability.Layer.Layer[i].Name.split(':').pop()

                ////console.log(json.Capability)

                var bbox = this.getBoundingBox(json.Capability.Layer.Layer[i])

                var minilayer = `${getmap}&SERVICE=WMS
                                &VERSION=${version}
                                &REQUEST=GetMap
                                &LAYERS=${name}
                                &STYLES=
                                &SRS=EPSG:4326
                                &TRANSPARENT=TRUE
                                &BBOX=${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}
                                &WIDTH=400
                                &HEIGHT=400
                                &FORMAT=image/png`

                var clean_minilayer = minilayer.replace(/\s+/g, '');

                var type = this.getLayerType(json.Capability.Layer.Layer[i].KeywordList)

                layers_global.push( { 'name': l_name ,
                                'workspace': json.Capability.Layer.Layer[i].Name.split(':')[0],
                                'legend': leg,
                                'getmap': getmap+"ows?",
                                'state': false,
                                'boundingbox': bbox,
                                'minilayer': clean_minilayer,
                                'type': type,
                                'description' : abs,
                                'opacity': 1
                                }
                            )

            }
            datajson_global.push(   {
                'id': this.state.geoserviceName,
                'name': json.Service.Title,
                'url' : getmap,
                'layers': layers_global,
                'metadata' :  { 'Titulo' : json.Service.Title,
                                'Resumen': json.Service.Abstract,
                                'contact' : {
                                                'ContactInformation': json.Service['ContactInformation']
                                                }
                                }
            })

            //update local state
            this.setState({fill: true})

            var options = {'transparent': true,
            'tiled':true,
            'format': 'image/png',
            'feature_count': 10
            }
            //update global state
            this.props.dispatch({   type: 'ADD_NEW_LN' ,
                                    St: true,
                                    Id :this.state.geoserviceName,
                                    source: this.create_WMS_source(json.Capability,options),
                                    name: (json.Service.Title !== '') ? json.Service.Title : json.Service.Name ,
                                    data: datajson_global
                });

            this.setState({message: "El Geoservicio se cargo correctamente! Encontrará las capas en la solapa Geoservicios disponibles."})

        }else{
            this.setState({message: "El Geoservicio no esta disponible. Consulte al administrador"})
        }
        e.preventDefault();

    }

    getLayer(layer){

        for(let i=0; i< this.items.length; i++){
                if (this.items[i].value === layer){
                    return this.items[i]
                }
        }
        return null
    }


    centerMapToLayer(layer){

        let obj_layer = this.getLayer(layer)
        if (obj_layer){
            let corner1 = L.latLng( obj_layer.miny, obj_layer.minx)
            let corner2 = L.latLng( obj_layer.maxy, obj_layer.maxx)
            let bounds = L.latLngBounds(corner1, corner2)
            this.map.flyToBounds(bounds)
            //console.log("dentro de centerMap")
        }
    }


    layerInPanel(name){

        var count = this.myRefPanel.current._layers.length

        for (let i=0; i < count; i++){

            if (this.myRefPanel.current._layers[i].name === name) {
                return 1
            }

        }
        return 0

    }

    loadLayeronMap(e){


        if ( !this.layerInPanel(e.target.value) ) {

            var url = this.state.urlwms.split("?")[0]
            var nexrad = L.tileLayer.wms(url, {
                layers: e.target.value,
                format: 'image/png',
                transparent: true,
                attribution: ""
            });

            //add layer to control panel
            this.myRefPanel.current.addOverlay(nexrad, e.target.value)

            //add layer to map after added to control panel
            this.map.addLayer(nexrad)
            //fly to center point to layer
            this.centerMapToLayer(e.target.value)

            this.setState({panel: true})

        }else{
            console.log("Yet exist layer in panel")
        }
        e.preventDefault();


    }

    componentDidUpdate(prevProps, prevState){

        //REQUERIDO: esta condicion chequea si el estado cambio, entonces actualiza.
        if (prevState.select !== this.state.select){
                console.log('foo prop changed')
        }
    }
    render() {

        return (
           <>
           <Row >
                <Col >
                    <Form >
                        <Form.Group className="mb-3" id="formOWS">
                            <Form.Label></Form.Label>
                            <InputGroup className="mb-3">

                                <Form.Control size="lg"
                                                type="text"
                                                placeholder="Nombre del geoservicio WMS"
                                                aria-label="Nombre del geoservicio WMS"
                                                onBlur={(event) => this.handleChangeName(event)}

                                                />
                                <Form.Text className="text-muted"></Form.Text>
                            </InputGroup>
                            <InputGroup className="mb-3">

                            <Form.Control size="lg"
                                            type="text"
                                            placeholder="URL del geoservicio WMS"
                                            aria-label="URL del geoservicio WMS"
                                            aria-required="true"
                                            onBlur={(event) => this.handleChangeURL(event)}

                                            />
                            <Form.Text className="text-muted"></Form.Text>

                            </InputGroup>

                            <Row >
                                <Col  >
                                    <div >{this.state.message}</div>
                                </Col>
                            </Row>

                        </Form.Group>
                    </Form>
                    </Col>

            </Row>
        </>
        );
      }


    }
export default connect(mapStateToProps)(FormOWS);
