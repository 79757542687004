import { GeoSearchControl } from 'leaflet-geosearch'
import { useMap } from 'react-leaflet'
import { useEffect } from 'react'
import L from 'leaflet';

/*Fix*/
const icon = L.icon({ iconUrl: "/leaflet_images/marker-icon.png" });

/**Fuente: https://smeijer.github.io/leaflet-geosearch/usage*/

// make new leaflet element
const Search = (props) => {
    const map = useMap() // access to leaflet map
    const { provider } = props

    useEffect(() => {
        const searchControl = new GeoSearchControl({
            provider,
            showMarker: true, // optional: true|false  - default true
            showPopup: true, // optional: true|false  - default false
            marker: {
              //optional: L.Marker    - default L.Icon.Default
              icon: icon, //new L.Icon.Default(),
              draggable: false,
            },
            popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label,
            resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
            maxMarkers: 1, // optional: number      - default 1
            retainZoomLevel: true, // optional: true|false  - default false
            animateZoom: true, // optional: true|false  - default true
            autoClose: true, // optional: true|false  - default false
            searchLabel: 'Buscar lugar por nombre o coordenadas...', // optional: string      - default 'Enter address'
            keepResult: true, // optional: true|false  - default false
            updateMap: true, // optional: true|false  - default true,
            style: 'bar', // optional: bar|button  - default button,
            autoComplete: true, // optional: true|false  - default true
            autoCompleteDelay: 250, // optional: number      - default 250
        })

        map.addControl(searchControl) // this is how you add a control in vanilla leaflet
        return () => map.removeControl(searchControl)
    }, [props])

    return null // don't want anything to show up from this comp
}
export default Search;