import * as WMS from "leaflet.wms";
import L from 'leaflet';
import 'bootstrap/dist/css/bootstrap.css';

//global to add result of diferents sources
var table = []
const MySource = WMS.Source.extend({

    /*
    Modulo que extiende a WMS.Source con el fin de mejorar el formato de la consulta de atributos.
    Se podrían ampliar otros hooks si asi fuera necesario.
    */

    'ajax': async function (url, callback) {

        try {
            var response = await fetch(url,
                        { method: 'GET', // *GET, POST, PUT, DELETE, etc.
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        //credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                        'Content-Type': 'application/text'
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        //redirect: 'follow', // manual, *follow, error
                        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                        body: data // body data type must match "Content-Type" header
                    })
            if(response.ok){
                var data =  await response.text();
                callback.call(this, data);

            }else{

                throw new Error(`HTTP error, status = ${response}`);
            }

        }catch(error){
            callback.call(this, "error");
            console.error(error);
        }
       
    },


    'showtable': function(layers_name, values,latlong){

        var j=0
        var i=0
        if (values !== null){

            for(j=0;j<layers_name.length;j++){

                table.push("<table class='table table-sm'> <thead><tr> <th>Atributo</th><th>Valor</th></tr></thead><tbody><tr><th>Capa</th><td>"+layers_name[j]+"</td></tr><tr><th>Lat-Lng</th><td> "+latlong.lat+"<br>"+latlong.lng+"</td></tr>")
                //console.log(values)
                if (values[i].split('=')[0].trim() === 'GRAY_INDEX'){

                    table.push("<tr><th>"+values[i].split('=')[0]+"</th>")
                    table.push("<td>"+values[i].split('=')[1]+"</td></tr>")
                    i++

                }else{
                    var k=i
                    if (values[k].split('=')[0].trim() === 'gid' ||
                        values[k].split('=')[0].trim() === 'id' ||
                        values[k].split('=')[0].trim() === 'fid' ||
                        values[k].split('=')[0].trim() === 'geom'){

                        table.push("<tr><th>"+values[k].split('=')[0]+"</th>")
                        table.push("<td>"+values[k].split('=')[1]+"</td></tr>")
                        k++

                        let key = values[k].split('=')[0].trim()
                        let val = values[k].split('=')[1].trim()

                        while( key !== 'gid' && k < values.length-1){
                            table.push("<tr><th>"+key+"</th>")
                            table.push("<td>"+val+"</td></tr>")
                            k++
                            key = values[k].split('=')[0].trim()
                            val = values[k].split('=')[1].trim()

                        }
                    }else if(values[k].split('=')[0].trim() === 'RED_BAND' || values[k].split('=')[0].trim() === 'GREEN_BAND' || values[k].split('=')[0].trim() === 'BLUE_BAND' || values[k].split('=')[0].trim() === 'ALPHA_BAND'){
                            for(j=0; j< values.length;j++){
                                table.push("<tr><th>"+values[j].split('=')[0]+"</th>")
                                table.push("<td>"+values[j].split('=')[1]+"</td></tr>")
                            }
                    }else{
                        console.log('Consulta desconocida: ', values)
                    }
                }
                table.push("</tbody></table>")
                //console.log(table)

            }

            return "".concat(...table)
        }else{
            return "Sin datos"
        }
    },

    'showFeatureInfo': function(latlng, info) {

        this.cleanTable()
        //info: contiene el string completo de la consulta al servidor
        //Expresion regular para obtener el nombre de la capa
        let layer_name = info.match(/[http|https]+:\/\/[\w\S(\.|:|/)][^']+/g)

        if(layer_name){

            //Expresion regular para obtener los atributos consultados
            let values = info.match(/(\w)+\s=\s((\d+.)|[0-9a-z-A-Z\[\\u00C0-\u017F].+)[^-]/g);
            let i=0
            let name = []
            for (i=0;i<layer_name.length;i++){

                name.push(layer_name[i].split(':')[2])
            }

            L.popup({className: 'custom-popup'})
            .setLatLng(latlng)
            .setContent(this.showtable(name, values,latlng))
            .openOn(this._map);
        }
    },

    'cleanTable': function(){

        /*clean table to query next*/
        this._map.on('click', function(){
            table=[]
        });
   }
});

const mysource = function (url, options) {
    return new MySource(url, options);
  };

  export default mysource