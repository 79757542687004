//react
import React from 'react';
import Button from 'react-bootstrap/Button'
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CollapsibleDiv from './CollapsibleDiv';


import '../styles/table_metadata.css'; // Ajusta la ruta según la ubicación de tu archivo CSS
import '../styles/show_doc.css'; // Ajusta la ruta según la ubicación de tu archivo CSS

const params = {
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
};

const styletext = { fontfamily: 'var(--bs-font-sans-serif)', 
                    lineHeight: '2.5',
                    textAlign: 'justify'
                }
class ShowDoc extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
            metadata: [],
            models: [],
            msgError: '',
            isLoading: false,
            noLeftMargin:true,
            lakes: []
    
		}

		this.sizes = { sm: 12, //screen width < 576px
						xs: 12,  //screen width ≥ 576px
						md: 12, //screen width ≥ 768px
						lg: 6, //screen width ≥ 992px
						xl: 6 //screen width ≥ 1200px
        }
        this.placement = props.placement
        this.documentation = props.text
        this.URL = props.conexion
    
    }    

    async searchSensors(lake){

        try {
            var response = await fetch(`${this.URL}list_sensors?lake=${lake}`, params)
                if(response.ok){
                    var data =  await response.json();
                    //console.log(data)
                   
                    var sensors = data.map( (el,index) => {
                                return { 
                                         "id": index, 
                                         "name": el.name
                                        }
                    })
                    return sensors
                    //this.setState({sensors: sensors})
                  
                }else{
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
            }catch(error){
                console.error(error);
            }
       
    }

    async searchModels(){

        try {
            // Wait for the asynchronous operation to complete
            await this.waitForData();
            
            this.state.lakes.forEach(el => {
                
                el.sensors.forEach(async em => {

                    var response = await fetch(`${this.URL}list_model?sensor=${em.name}&lake=${el.lake}`, params)
                    if(response.ok){
                        var data =  await response.json();

                        this.setState({ models: [{ sensor: em.name, lake:el.lake, data: data }, ...this.state.models] })
                    }
                })

            })

     
        } catch (error) {
            console.error("Error:", error);
        }
   }

    waitForData() {
        return new Promise(resolve => {
            const checkData = () => {
                if (this.state.lakes.length !== 0) {
                    //console.log("promesa resuelta...:", this.state.lakes)
                    resolve();
                } else {
                    setTimeout(checkData, 500);
                }
            };
            checkData();
        });
    }

    async searchMetadata(){

        this.setState({isLoading: true})
        try {
            var response = await fetch(`${this.URL}/list_lakes`, params);
                if(response.ok){
                    var data =  await response.json();

                    if(data){
                        var result = []
                        result = data.map(el => {

                                return { "lake": el.name,
                                         "province": el.province.name,
                                         "sensors": el.sensors.map(el =>{
                                                        return { "name": el.name,
                                                                  "product": el.product_name,

                                                                }

                                                        })

                                        }

                        })

                    }                    
                    this.setState({ lakes: result })
                    
                }else{
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
            }catch(error){
                this.setState({isLoading: false})
                this.setState({msgError: this.documentation.errors.filter(el => 'api' in el)[0].api})
                console.error(error);
            }
            this.setState({isLoading: false})

    }

	componentDidMount(){
        this.searchMetadata()
        this.searchModels()
    }

	openBox(){this.setState({show: true})}

	closeBox(){this.setState({show: false})}


 render() {

    return (

    <>
    <OverlayTrigger placement="auto-start"
            delay={{ show: 250, hide: 100 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                            {"Documentación"}
                </Tooltip>
                )}
                >
            <Button variant="outline-success" onClick={()=>this.openBox()}>
            Documentacion
                {/*<FontAwesomeIcon icon={faInfoCircle} size='lg'/>*/}
            </Button>
    </OverlayTrigger>
      <Offcanvas    //className="offcanvas-end-doc"
                    show={this.state.show}
	  				onHide={()=>this.closeBox()}
	  				scroll='true'
					backdrop='true'
                    placement='end'
                    bsPrefix='show-doc-box'
                    >
        <Offcanvas.Header closeButton >
	          <Offcanvas.Title>
               {this.state.msgError}

			  </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
             <Tabs
                    defaultActiveKey="metadata"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="metadata" title="Metadatos">
                        <div className='box-docs-5' id={Math.random()}>
                        { this.state.isLoading && <Spinner animation="border" style={{ width: "8rem", height: "8rem" }}/>}
                         {/*this.renderTableMetadata() --> cosdigo viejo*/}
                         <p>Metadatos disponibles por Lagos</p>
                            <div className="App">
                            
                            {this.state.lakes ? (
                            
                                this.state.lakes.map(el => {
                                        return <>
                                                 <CollapsibleDiv title={el.lake}>
                                                    <span style={{fontSize: '16px'}}>Provincia:{el.province}</span>
                                                    <p>
                                                    <span style={{fontSize: '16px'}}>Sensores y productos por lago:</span>
                                                    </p>
                                                    {
                                                        
                                                        el.sensors.map(sensor =>{ 
                                                          return <CollapsibleDiv title={sensor.name +' '+sensor.product}>
                                                                    <p>Modelos aplicados:</p>
                                                                    {this.state.models.map( model =>{
                                                                         if(model.lake === el.lake && model.sensor === sensor.name){
                                                                             return  model.data.map( d => {
                                                                                        return <CollapsibleDiv title={d.name}>
                                                                                                <table className="styled-table">
                                                                                                <thead>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                 <tr>
                                                                                                    <td>Descripción</td><td>{d.description}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Autores</td><td>{d.authors}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Formula</td><td>{d.formula}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Bandas</td><td>{JSON.stringify(d.bands)}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Unidades</td><td>{d.units}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Valor Nodata</td><td>{d.nodata}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Mascaras aplicadas</td><td>{(d.masks.length !== 0)? JSON.stringify(d.masks): 'None' }</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>Rango valido</td><td>{JSON.stringify(d.valid_range)}</td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                                </table>
                                                                                                </CollapsibleDiv>
                                                                                                                                                                               
                                                                                    })
                                                                         }
                                                                    })
                                                                    }
                                                                 </CollapsibleDiv>       
                                                        })
                                                    }
                                                </CollapsibleDiv>
                                            </>
                                    })
                                    
                                    

                                ) 
                            : 
                                (
                                <p>Cargando datos...</p>
                                )
                            }

                               
                                
               
                            </div>
                        </div>

                    </Tab>
                    <Tab eventKey="geoservices" title="Geoservicios">
                        <div className='box-docs-5' id={Math.random()}>
                            <span style={styletext}>{this.documentation.geoservices.text}</span>
                        </div>
                        <OverlayTrigger 
                                        rootClose={true}
                                        rootCloseEvent="mousedown"
                                        placement="auto"
                                        trigger="click"
                                        overlay={(props) => (
                                                    <Tooltip id="button-tooltip" {...props}>
                                                        Enlace copiado!
                                                    </Tooltip>
                                                )}
                                        >
                                 <Button 
                                     size="sm" 
                                      variant="outline-primary"
                                      onClick={() =>  navigator.clipboard.writeText(this.documentation.geoservices.wms)}
                                    >
                                  {'WMS'}
                                  </Button>
                                  
                        </OverlayTrigger>
                        {' '}
                        <OverlayTrigger placement="auto"
                                        rootClose={true}
                                        rootCloseEvent="mousedown"
                                        trigger="click"
                                        overlay={(props) => (
                                                    <Tooltip id="button-tooltip" {...props}>
                                                        Enlace copiado!
                                                    </Tooltip>
                                                )}
                                        >
                        
                                    <Button 
                                        size="sm" 
                                        variant="outline-primary"
                                        onClick={() =>  navigator.clipboard.writeText(this.documentation.geoservices.wfs)}
                                    >
                                  {'WFS'}
                                  </Button>
                        </OverlayTrigger>
                        {' '}
                        <OverlayTrigger placement="auto"
                                        rootClose={true}
                                        rootCloseEvent="mousedown"
                                        trigger="click"
                                        overlay={(props) => (
                                                    <Tooltip id="button-tooltip" {...props}>
                                                        Enlace copiado!
                                                    </Tooltip>
                                                )}
                                        >
                        
                                    <Button 
                                        size="sm" 
                                        variant="outline-primary"
                                        onClick={() =>  navigator.clipboard.writeText(this.documentation.geoservices.wcs)}
                                    >
                                  {'WCS'}
                                  </Button>
                        </OverlayTrigger>


                    </Tab>
                    <Tab eventKey="contact" title="Contacto" >
                    <div className='box-docs-5' id={Math.random()}>
                        {this.documentation.contact}
                    </div>
                    </Tab>
                    </Tabs>

			</Offcanvas.Body>
      </Offcanvas>

    </>

        )
    }
}
export default ShowDoc;
